<script setup lang="ts">
import { useMessageStore } from '@/stores/message';
import { onMounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import router from '@/router';
import { useAccessStore, type OperatorAccess } from '@/stores/access';
const accessStore = useAccessStore();
const { accessBureauOperatorList, accessBureauList } = storeToRefs(accessStore);
import {useSiteOperatorStore, type Operator} from '@/stores/site-operator';
const siteOperatorStore = useSiteOperatorStore();
import { useEmailStore, type SendEmailTest, type Bureau } from '@/stores/email';
const messageStore = useMessageStore();
const emailStore = useEmailStore();
import { useTenancyStore, type Customer } from '@/stores/tenancy'
const tenancyStore = useTenancyStore();
// Manage Subject
const noteUrl = computed(() => {
  return window.location.href;
})

enum ViewState {
  Draft,
  Preview,
  History,
};
const currentViewState = ref<ViewState>(ViewState.Draft);
const subject = ref<string>("Latest Alert from Personam.ai")
const validSubject = ref<{state: boolean, message: string}>({
  state: true,
  message: ""
});
const subjectMaxCount = 50;
const subjectMinCount = 10
async function validateSubject(event: Event): Promise<void> {
  const content = subject.value;
  if (content.length < subjectMinCount) {
    validSubject.value = {
      state: false,
      message: `Subject must be at least ${subjectMinCount} characters long - ${content.length} of ${subjectMinCount} provided.`
    };
    return;
  }
  if (content.length == subjectMaxCount) {
    validSubject.value = {
      state: true,
      message: `${content.length} characters of ${subjectMaxCount} provided.`
    };
    return;
  }
  validSubject.value = {
    state: true,
    message: ""
  };
}
// Manage Note
const note = ref<string>("Testing Note")
const isResponed = ref<boolean>(false);
onMounted(async () => {
  await tenancyStore.loadTenants();
  const customerSlug = router.currentRoute.value.params.customerSlug!;
  tenancyStore.setCurrentCustomerWithSlug(customerSlug as string);
  await siteOperatorStore.loadOperators(tenancyStore.current_customer!)
  if (siteOperatorStore.operators.length > 0) {
    isResponed.value = true;
  }
})

// Email Notification
const emailBodyContentPreview = computed(() => {
  return `From: Amelia I. &lt;ai@personam.ai&gt;
Subject: ${subject.value}

Personam has detected a threat to your organization. Please review for more information.
${noteUrl.value}

An operator from your organization has included a note:
${note.value}`
});
const emailBodyContent = computed(() => {
  return `Personam has detected a threat to your organization. Please review for more information.
${noteUrl.value}

An operator from your organization has included a note:
${note.value}`
});
async function sendTest(accessOperator: Operator): Promise<void> {
  if (emailBodyContentPreview.value.length === 0) {
    return;
  }
  // const emails = accessBureauOperatorList.value(focusBureau.value!).filter((oa: OperatorAccess) => oa.email_notification === true).map((oa: OperatorAccess) => oa.email);
  const bureau = ref<Bureau|undefined>(undefined);
  bureau.value = {guid: accessOperator.sites[0].guid, name:accessOperator.sites[0].name, slug: accessOperator.sites[0].slug};

  console.log('email', accessOperator.email)
  const send_email_test = {
    recipients: [accessOperator.email],
    subject: subject.value,
    body: emailBodyContent.value,
  } as SendEmailTest;
  const message = await emailStore.sendTest(send_email_test, bureau as unknown as Bureau);
  await messageStore.handle(message);
  // const has_error = await emailStore.sendTest(send_email_test, focusBureau.value!.bureau);
  // if (has_error != null) {
  //   validEmail.value = {
  //     state: false,
  //     message: `${has_error.code}: ${has_error.message}`
  //   };
  // }
}
</script>
<template>
    <section class="columns">
      <div class="column is-three-quarters">
        <div class="container">
          <div v-if="currentViewState === ViewState.Draft" class="columns">
            <div class="column is-two-thirds">
              <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                <thead>
                  <tr>
                    <th> Operator </th>
                    <th> Receive Email Notifications </th>
                  </tr>
                </thead>
                <tbody v-if="isResponed">
                  <tr v-for="oa in siteOperatorStore.operators" :key="oa.guid">
                    <td>
                      <span class="fa fa-user"></span>
                      {{ oa.name }} <br/>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;{{ oa.email }}&gt;
                    </td>
                    <td class="has-text-centered">
                      <div class="field is-grouped">
                        <div class="control">
                          <button
                            @click="sendTest(oa)"
                            class="button">
                            Send Test
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>