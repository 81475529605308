import { defineStore } from "pinia";
import { useConfigStore } from "./config";
import { AxiosClient } from "./axios-client";
import { useTenancyStore, type Customer, type Site } from "./tenancy";

export interface Operator {
  guid: string,
  created: Date,
  updated: Date,
  name: string,
  email: string,
  email_notification: boolean,
  sms_notification: boolean,
  phone_number: string | null,
  sites: Site[],
}
export const useSiteOperatorStore = defineStore('siteOperator', {
  state: () => ({
    _operators: [] as Operator[],
    _currentOperator: null as Operator | null
  }),
  getters: {
    operators: (state): Operator[] => {
      return state._operators;
    },
    currentOperator: (state): Operator | null => {
      return state._currentOperator
    },
    allSites: (state): Site[] => {
      const sites = [];
      for(var idx = 0; idx < state._operators.length; idx++) {
        let opt = state._operators[idx];
        for(var jdx = 0; jdx < opt.sites.length; jdx++) {
          let site = opt.sites[jdx];
          const site_ids = sites.reduce((acc: string[], site: Site) => {
            acc.push(site.guid)
            return acc
          }, [])
          if (site_ids.indexOf(site.guid) < 0) {
            sites.push(site)
          }
        }
      }
      return sites;
    }
  },
  actions: {
    setCurrentOperatorByGuid(guid: string): void {
      for(var idx = 0; idx < this.operators.length; idx++) {
        if(guid == this.operators[idx].guid) {
          this._currentOperator = this.operators[idx];
          break;
        } else if (idx + 1 == this.operators.length) {
          console.warn("Operator Guid Missing")
        }
      }
    },
    setCurrentOperator(operator: Operator): void {
      this._currentOperator = operator;
    },
    updateCurrentOperator(property: string, value: any): void {
      if (this._currentOperator == null) {
        return;
      }
      if (property == 'name') {
        this._currentOperator.name = value;
      } else if (property == 'email') {
        this._currentOperator.email = value;
      } else if (property == 'phoneNumber') {
        this._currentOperator.phone_number = value;
        this._currentOperator.sms_notification = true;
      } else if (property == 'smsNotification') {
        this._currentOperator.sms_notification = value;
      } else if (property == 'sites') {
        this._currentOperator.sites = value;
      } else {
        console.error("Unable to update Operator", property);
      }
    },
    async stamp(): Promise<void> {
      const payload = {
        name: this.currentOperator!.name,
        email: this.currentOperator!.email,
        phone_number: this.currentOperator!.phone_number,
        site_ids: this.currentOperator?.sites.reduce((acc: string[], site: Site) => {
          acc.push(site.guid);
          return acc;
        }, [])
      };
      const configStore = useConfigStore();
      const tenancyStore = useTenancyStore();
      var url = `${configStore.apiUrl}/cs/${tenancyStore.current_customer!.guid}/operator/${this.currentOperator!.guid}/stamp`;
      if (this.currentOperator?.guid == '') {
        url = `${configStore.apiUrl}/cs/${tenancyStore.current_customer!.guid}/operator/create`;
      }
      try {
        const resp = await AxiosClient.post(url, payload);
        if (resp.status != 202 && resp.status != 201) {
          console.error("Unable to create/update operator", resp.data);
        }
      } catch (err: any) {
        console.error("Unable to create/update operator", err);
      }
    },
    async clearOperator(): Promise<void> {
      let dontBreak = true;
      let brace = 0;
      while (dontBreak && brace < 100) {
        brace = brace + 1
        for(var idx = 0; idx < this._operators.length; idx++) {
          if (idx + 1 == this._operators.length) {
            dontBreak = false;
          }
          if(this._operators[idx].guid == '') {
            this._operators.splice(idx, 1)
            break;
          }
        }
      }
      this._currentOperator = null;
    },
    async createOperator(): Promise<void> {
      const operator = {
        guid: '',
        created: new Date(),
        updated: new Date(),
        name: '',
        email: '',
        email_notification: true,
        sms_notification: false,
        phone_number: null,
        sites: []
      } as Operator;
      this._operators.push(operator);
      this._currentOperator = operator;
    },
    async loadOperators(customer: Customer): Promise<void> {
      const configStore = useConfigStore();
      const url = `${configStore.apiUrl}/cs/${customer.guid}/operator`
      try {
        const resp = await AxiosClient.get(url);
        if (resp.status != 200) {
          console.error("Unable to load Operators from Customer")
        }
        const operators = [];
        for(var idx = 0; idx < resp.data.length; idx++) {
          const opt = resp.data[idx];
          operators.push({
            guid: opt.guid,
            created: new Date(opt.created),
            updated: new Date(opt.updated),
            name: opt.name,
            email: opt.email,
            email_notification: opt.email_notification,
            sms_notification: opt.sms_notification,
            phone_number: opt.phone_number || null,
            sites: opt.sites.reduce((acc: Site[], val: Site) => {
              acc.push({
                guid: val.guid,
                created: new Date(val.created),
                updated: new Date(val.updated),
                name: val.name,
                slug: val.slug,
                name_abbreviation: val.name_abbreviation,
                open_alert_count: val.open_alert_count,
                open_notice_count: val.open_notice_count,
                personam_count: val.personam_count,
                cohort_count: val.cohort_count,
                critical_intake_count: val.critical_intake_count,
                nominal_intake_count: val.nominal_intake_count,
              })
              return acc;
            }, [])
          } as Operator);
        }
        this._operators = operators;
      }
      catch(err: any) {
        console.error("Unable to load Operators from Customer", err);
      }
    }
  }
})