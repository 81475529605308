<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import { useMessageStore, type Message, MessageStatus } from '@/stores/message';
import { storeToRefs } from 'pinia';
const messageStore = useMessageStore();
const { messages } = storeToRefs(messageStore);

const messageRemoveDelay = 10.0;
const timer: ReturnType<typeof setTimeout> = setTimeout(() => '', 1000);
let messageManager: typeof timer;
function manageMessages() {
  const current_ts = new Date().getTime();
  for(let idx = 0; messages.value.length > idx; idx++) {
    const message = messages.value[idx];
    const diff = (current_ts - message.ts.getTime()) / 1000;
    if (diff > messageRemoveDelay) {
      messages.value.splice(idx, 1);
    }
  }
  messageManager = setTimeout(manageMessages, 1000);
}
onMounted(async () => {
  messageManager = setTimeout(manageMessages, 1000);
});
onUnmounted(async () => {
  clearTimeout(messageManager);
});
async function dismissMessage(message: Message): Promise<void> {
  const index = messages.value.indexOf(message);
  if (index > -1) {
    messages.value.splice(index, 1);
  }
}
</script>
<template>
  <div class="message-container">
    <TransitionGroup name="message-container" tag="div" class="message-container">
      <div class="card noti-card" :key="idx" v-for="(message, idx) in messages">
        <div class="card-content">
          <div class="content">
            <span class="tag" :class="{
              'is-info': message.status == MessageStatus.Info,
              'is-danger': message.status == MessageStatus.Warning || message.status == MessageStatus.Danger,
              }">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
            &nbsp;
            <span :class="{
              'has-text-info': message.status == MessageStatus.Info,
              'has-text-danger': message.status == MessageStatus.Warning || message.status == MessageStatus.Danger,
            }">
              {{ message.message }}
            </span>
          </div>
        </div>
        <footer class="card-footer">
          <a href="#" @click="dismissMessage(message);" class="card-footer-item"> Dismiss </a>
        </footer>
      </div>
    </TransitionGroup>
  </div>
</template>
<style scoped lang="sass">
div.message-container
  position: absolute
  right: 40px
  top: 100px
  z-index: 25
  width: 450px
div.message-container div.card:not(:first-child)
  margin-top: 1.5em
.message-container-enter-active, .message-container-leave-active
  transition: all 0.5s ease
.message-container-enter-from,.message-container-leave-to
  opacity: 0
  transform: translateX(30px)

</style>