<template>
  <div class="integration-box">
    <div class="box">
      <div class="media">
        <div class="media-left">
          <figure class="image">
            <img width="120" src="/public/sflow-logo.gif" alt="SFlow Logo" />
          </figure>
        </div>
        <div class="media-content">
          <p class="is-5">
            Configure
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.media-content {
  font-size: 20px;
  padding-top: 10px;
  padding-left: 10px;
}
</style>