<script setup lang="ts">
import SiteNetworkDataConfigureStreamCard from '../base/SiteNetworkDataConfigureStreamCard.vue'

import { useSiteOnboardingModal } from '@/stores/siteOnboardingModal';

const siteOnboardingModal = useSiteOnboardingModal();
async function handleToggle() {
  await siteOnboardingModal.toggle({
    component: SiteNetworkDataConfigureStreamCard,
    routeName: 'onboarding-site-network-data-inbound-configure-cisco-netflow',
    title: 'Request Cisco netflow Collection',
  });
}
</script>
<template>
  <div class="integration-box" @click="handleToggle()">
    <div class="box" style="padding-top: .75rem; padding-bottom: 0;">
      <div class="media">
        <div class="media-left">
          <figure class="image">
            <img src="/public/cisco-logo.svg" alt="Cisco Logo">
          </figure>
        </div>
        <div class="media-content">
          <p class="is-5">
            <!-- https://www.cisco.com/c/dam/en/us/td/docs/security/stealthwatch/netflow/Cisco_NetFlow_Configuration.pdf -->
            NetFlow
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.media-left {
  padding-top: 12px;
}
.media-content {
  font-size: 20px;
  padding-top: 14px;
  padding-left: 18px;
}
</style>