<script lang="ts" setup>
import { defineProps, type PropType, ref } from 'vue'
import { type AlertGrid, type Workflow_State, Target_Type } from '@/stores/alert-grid'
import { useAlertDetectorStore } from '@/stores/alert-detector'
import moment from 'moment'
import { useConfigStore, DeploymentStatus } from '@/stores/config';
import { storeToRefs } from 'pinia';

const props = defineProps({
  alert: {
    type: Object as PropType<AlertGrid>,
    required: true
  },
  statusFilter: {
    type: Array as PropType<Workflow_State[]>,
    required: false
  }
})
const configStore = useConfigStore();
const { deploymentStatus } = storeToRefs(configStore);
const alertDetectorStore = useAlertDetectorStore()
enum ToolkitStatus {
  Loading = 'loading',
  Ready = 'ready'
}
const currentToolKitStatus = ref<ToolkitStatus>(ToolkitStatus.Ready);
async function openToolkit(alert: AlertGrid): Promise<void> {
  if (currentToolKitStatus.value === ToolkitStatus.Loading) {
    return;
  }
  currentToolKitStatus.value = ToolkitStatus.Loading;
  await alertDetectorStore.loadDetectorToken();
  const protocol = deploymentStatus.value == DeploymentStatus.Production? 'https': 'http';
  const head = `${protocol}://${alertDetectorStore.detector?.detector_website}`;
  const tailValue = encodeURIComponent(alertDetectorStore.detector?.detector_token!);
  const tailWhole = `?Authorization=${tailValue}`;
  const tailWithTail = `${tailWhole}&Target=${encodeURIComponent(alert.target_id)}`
  const detailsUrl = `${head}/ClientWebUI/Account/ExternalLogin${tailWithTail}`;
  window.open(detailsUrl, '_blank');
  currentToolKitStatus.value = ToolkitStatus.Ready;
}

</script>
<template>
  <tr v-show="statusFilter?.length === 0 || statusFilter?.find((value) => { return value == alert.workflow_state })">
    <td class="has-text-center">
      {{ moment(alert.timestamp).utc().format('MMMM Do, YYYY') }}
    </td>
    <td class="has-text-left">
      <a
        class="is-link"
        :class="{
          'disabled-link': currentToolKitStatus === ToolkitStatus.Loading
        }"
        data-tooltip="Alert was generated for a Device"
        @click="openToolkit(alert)">
        <span class="icon is-small">
          <i
            class="fas"
            :class="{
              'fa-desktop': currentToolKitStatus === ToolkitStatus.Ready && alert.target_type == Target_Type.DEVICE,
              'fa-user': currentToolKitStatus === ToolkitStatus.Ready && alert.target_type == Target_Type.USER,
              'fa-spinner do-spin': currentToolKitStatus === ToolkitStatus.Loading,
            }"
          ></i>
        </span>
        {{ alert.target_id }}
      </a>
    </td>
    <td> {{ Math.round(alert.threat_score * 100) }}% </td>
    <td> 
      <button @click="openToolkit(alert)" :disabled="currentToolKitStatus === ToolkitStatus.Loading" class="button color-primary-alt has-text-white"> Investigate </button>
    </td>
  </tr>
</template>
<style lang="scss" scoped>
.disabled-link {
  color: black;
  cursor: inherit;
}
.do-spin {
  animation: rotation 1s linear infinite;
}
</style>