<script setup lang="ts">
import SideBar from '@/components/tenant/SideBar.vue'
import router from '@/router'
import AlertGrid from '@/components/tenant/alert/AlertGrid.vue'
import { onMounted } from 'vue'
import { useTenancyStore, type Customer } from '@/stores/tenancy'
import { useAlertGridStore } from '@/stores/alert-grid'

const tenancyStore = useTenancyStore();
const alertGridStore = useAlertGridStore();
onMounted(async () => {
  const customerSlug = router.currentRoute.value.params.customerSlug;
  const siteSlug = router.currentRoute.value.params.siteSlug;
  await tenancyStore.loadTenants()
  if (customerSlug === undefined || siteSlug === undefined) {
    router.push({name: 'customer-dashboard', params: {customerSlug: tenancyStore.customers[0].slug}})
  } else {
    tenancyStore.setCurrentSiteWithSlug(customerSlug as string, siteSlug as string);
    router.push({name: 'site-alert', params: {
      customerSlug: tenancyStore.current_customer!.slug,
      siteSlug: siteSlug
    }})
    await alertGridStore.loadGridData()
  }
});
</script>
<template>
  <main class="section columns">
    <div class="column is-one-quarter">
      <SideBar :tenants="tenancyStore.customers" />
    </div>
    <div class="column is-three-quarter">
      <AlertGrid />
    </div>
  </main>
</template>
<style scoped lang="sass">
.large-action-icon
  font-size: 32px
  line-height: 32px
.force-text-center
  text-align: center !important
.embold-link
  font-weight: 800
  font-size: 1.1em !important
</style>