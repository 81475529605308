<script lang="ts" setup>
import DownloadSensorAgentCard from '../logo-cards/intake/DownloadSensorAgentCard.vue';
import PCapFileUploadCard from '../logo-cards/intake/PCapFileUploadCard.vue';
import SFlowCard from '../logo-cards/intake/SFlowCard.vue';
import CiscoNetflowCard from '../logo-cards/intake/CiscoNetflowCard.vue';
import GoogleCloudCard from '../logo-cards/intake/GoogleCloudCard.vue';
import AWSCard from '../logo-cards/intake/AWSCard.vue';
import MicrosoftAzureCard from '../logo-cards/intake/MicrosoftAzureCard.vue';
import ElasticCard from '../logo-cards/intake/ElasticCard.vue';
import SplunkCard from '../logo-cards/intake/SplunkCard.vue';

import { onMounted } from 'vue';
import { useProfileStore } from '@/stores/profile';
import { useSiteStore } from '@/stores/site';
import { storeToRefs } from 'pinia';
import router from '@/router';
import { useGtag } from 'vue-gtag-next'; 

const gtag = useGtag(); 
const profileStore = useProfileStore();
const siteStore = useSiteStore();
const { site } = storeToRefs(siteStore);

onMounted(async () => {
  await siteStore.loadLocalSite();
  await profileStore.loadLocalSiteProfile();
})
async function handleComplete(): Promise<void> {
  router.push({'name': 'site-detail', params: {'slug': site.value.slug}});
}

function trackCardClick(cardName: string) {
  gtag.event('click', {
    event_category: 'Data Source Cards',
    event_label: cardName,
    value: cardName,
  });
}
</script>
<template>
  <div class="card">
    <div style="padding-top: .75rem;" class="card-content">
      <div class="media">
        <div class="media-left">
          <figure class="image is-48x48">
            <img src="/public/personam-alert.svg" alt="Placeholder image">
          </figure>
        </div>
        <div class="media-content">
          <p class="title is-4" style="padding-top:10px;"> Site Network Intake </p>
          <p> Personam is capable of pulling data from your existing tech stack minimizing input from your IT staff.  If you prefer, you can also deploy the Personam Sensor Agent as a mechanism to generate the data needed by the AI engine, called the Personam Detector. </p>
        </div>
      </div>
      <hr/>
      <div class="content">
        <div class="columns">
          <div class="column is-4 clickable" @click="trackCardClick('Personam.AI Sensor Agent')">
            <DownloadSensorAgentCard />
          </div>
          <div class="column is-4 clickable" @click="trackCardClick('Cisco NetFlow')">
            <CiscoNetflowCard />
          </div>
          <div class="column disable-card is-4" @click="trackCardClick('Sflow Configure')">
            <SFlowCard />
          </div>
        </div>
        <div class="columns">
          <div class="column disable-card is-4" @click="trackCardClick('PCap Data Upload')">
            <PCapFileUploadCard />
          </div>
          <div class="column disable-card is-4" @click="trackCardClick('Elastic SIEM')">
            <ElasticCard />
          </div>
          <div class="column disable-card is-4" @click="trackCardClick('Google Cloud')">
            <GoogleCloudCard />
          </div>
        </div>
        <div class="columns">
          <div class="column disable-card is-4" @click="trackCardClick('Amazon Web Service')">
            <AWSCard />
          </div>
          <div class="column disable-card is-4" @click="trackCardClick('Microsoft Azure')">
            <MicrosoftAzureCard />
          </div>
          <div class="column disable-card is-4" @click="trackCardClick('Splunk SIEM')">
            <SplunkCard />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.media-content .is-5 {
  margin-top: 10px;
}
.clickable {
  cursor: pointer;
}
.disable-card {
  -webkit-filter: grayscale(100%) !important;
  filter: none;
}
</style>