import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from "@sentry/vue";
import VueGtag from "vue-gtag-next";

import App from './App.vue';
import router from './router';

import './assets/main.scss';

const app = createApp(App);
// app.use(VueGtag, {
//   property: { 
//     id: "G-XSP3V79V91",
//     params: {
//       send_page_view: true,
//     },
//   },
//   appName: "Dashboard",
// });
const debugState = import.meta.env.VITE_DEPLOYMENT_STATUS;
if (debugState === 'production') {
  // Setup Sentry
  // Sentry.init({
  //   app,
  //   dsn: "https://3f3157e94c94162cbb93778283b99598@o4506693020155904.ingest.sentry.io/4506693022842880",
  //   integrations: [
  //     Sentry.browserTracingIntegration({router}),
  //     Sentry.replayIntegration(),
  //   ],
  //   tracesSampleRate: 0.1,
  //   tracePropagationTargets: ['localhost', /^https:\/\/api\.personam\.ai/],
  //   replaysSessionSampleRate: 0.1,
  //   replaysOnErrorSampleRate: 1.0,
  // });
  
} else if (debugState === undefined) {
  import.meta.env.VITE_DEPLOYMENT_STATUS = 'local';
}
console.info('Deployment Status:',import.meta.env.VITE_DEPLOYMENT_STATUS);
app.use(createPinia());
app.use(router);
app.mount('#app');
