<script setup lang="ts">
import SideBar from '@/components/tenant/SideBar.vue'
import SitePanel from '@/components/tenant/SitePanel.vue'
import { onMounted } from 'vue'
import { useTenancyStore } from '@/stores/tenancy'

const tenancyStore = useTenancyStore();
onMounted(async () => {
  await tenancyStore.loadTenants();
});
</script>
<template>
  <main class="section columns">
    <div class="column is-one-quarter">
      <SideBar :tenants="tenancyStore.customers" />
    </div>
    <div class="column is-three-quarter">
      <SitePanel :site="tenancyStore.current_site" />
      Comming Soon
    </div>
  </main>
</template>
<style scoped lang="sass">
.large-action-icon
  font-size: 32px
  line-height: 32px
.force-text-center
  text-align: center !important
.embold-link
  font-weight: 800
  font-size: 1.1em !important
</style>