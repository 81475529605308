<script setup lang="ts">
import SiteOnboardingSiteCreation from '@/components/site/onboarding/stages/SiteOnboardingSiteCreation.vue'
import SiteOnboardingProfileSurvey from '@/components/site/onboarding/stages/SiteOnboardingProfileSurvey.vue'
import SiteOnboardingNetworkTrafficTap from '@/components/site/onboarding/stages/SiteOnboardingNetworkTrafficTap.vue'
import SiteOnboardingIncidentResponse from '@/components/site/onboarding/stages/SiteOnboardingIncidentResponse.vue'
import SiteOnboardingNextGenAndXDRResponse from '@/components/site/onboarding/stages/SiteOnboardingNextGenAndXDRResponse.vue'
import SiteOnboardingEnhancements from '@/components/site/onboarding/stages/SiteOnboardingEnhancements.vue'
import SiteOnboardingViewModal from '@/components/site/onboarding/SiteOnboardingViewModal.vue'

import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import router from '@/router'; 
import { useProfileStore } from '@/stores/profile';
import { useSiteStore } from '@/stores/site';
import { useTenantStore } from '@/stores/tenant';

const siteStore = useSiteStore();
const profileStore = useProfileStore();
const { profile, initialSite } = storeToRefs(profileStore);
const tenantStore = useTenantStore();
const { tenantSlug } = storeToRefs(tenantStore);

enum Stage {
  ProfileSurvey = 'profile-survey',
  SiteCreation = 'site-creation',
  SiteReview = 'site-review',
  NetworkTrafficTap = 'network-traffic-tap',
  IncidentResponse = 'incident-response',
  ForensicData = 'forensic-data',
  NextGenAndXDRResponse = 'next-gen-and-xdr-response',
  Enhancements = 'enhancements',
};
const stage = ref<Stage | null>(null);
async function syncViewToRouter(): Promise<void> {
  const routeName = router.currentRoute.value.name;
  if (routeName === 'site-onboarding-survey') {
    stage.value = Stage.ProfileSurvey;
  } else if (routeName === 'site-onboarding-site-creation') {
    stage.value = Stage.SiteCreation;
  } else if (routeName === 'site-onboarding-site-review') {
    if (tenantSlug.value === null) {
      router.push({ name: 'site-onboarding-site-creation' });
    } else {
      stage.value = Stage.SiteReview;
    }
  } else if (routeName === 'site-onboarding-network-traffic-tap') {
    if (tenantSlug.value === null) {
      router.push({ name: 'site-onboarding-site-creation' });
    } else {
      stage.value = Stage.NetworkTrafficTap;
    }
  } else if (routeName === 'site-onboarding-incident-response') {
    if (tenantSlug.value === null) {
      router.push({ name: 'site-onboarding-site-creation' });
    } else {
      stage.value = Stage.IncidentResponse;
    }
  } else if (routeName === 'site-onboarding-forensic-data') {
    if (tenantSlug.value === null) {
      router.push({ name: 'site-onboarding-site-creation' });
    } else {
      stage.value = Stage.ForensicData;
    }
  } else if (routeName === 'site-onboarding-next-gen-and-xdr-response') {
    if (tenantSlug.value === null) {
      router.push({ name: 'site-onboarding-site-creation' });
    } else {
      stage.value = Stage.NextGenAndXDRResponse;
    }
  } else if (routeName === 'site-onboarding-enhancements') {
    if (tenantSlug.value === null) {
      router.push({ name: 'site-onboarding-site-creation' });
    } else {
      stage.value = Stage.Enhancements;
    }
  } else {
    // Use this section to send the user to the correct route.
    stage.value = Stage.SiteCreation;
    console.error('Unsupported Route Name', routeName);
  }
}
onMounted(async () => {
  await Promise.all([
    profileStore.loadProfile(),
    profileStore.loadSurvery(),
    profileStore.loadInitialSite(),
  ]);
  await siteStore.loadLocalSite();
  await profileStore.loadLocalSiteProfile();
  await syncViewToRouter();
});
async function updateStage(newStage: Stage): Promise<void> {
  if (profile.value.surveyComplete === false) {
    return;
  }
  if (newStage == Stage.ProfileSurvey) {
    router.push({ name: 'site-onboarding-survey'})
    stage.value = newStage;
  } else if (newStage == Stage.SiteCreation || newStage == Stage.SiteReview) {
    if (initialSite.value !== null) {
      router.push({ name: 'site-onboarding-site-review', params: {slug: tenantSlug.value}})
    } else {
      router.push({ name: 'site-onboarding-site-creation'})
    }
    stage.value = newStage;
  } else if (newStage == Stage.NetworkTrafficTap) {
    router.push({ name: 'site-onboarding-network-traffic-tap', params: {slug: tenantSlug.value}})
    stage.value = newStage;
  } else if (newStage == Stage.IncidentResponse) {
    router.push({ name: 'site-onboarding-incident-response', params: {slug: tenantSlug.value}})
    stage.value = newStage;
  } else if (newStage == Stage.ForensicData) {
    router.push({ name: 'site-onboarding-forensic-data', params: {slug: tenantSlug.value}})
    stage.value = newStage;
  } else if (newStage == Stage.NextGenAndXDRResponse) {
    router.push({ name: 'site-onboarding-next-gen-and-xdr-response', params: {slug: tenantSlug.value}})
    stage.value = newStage;
  } else if (newStage == Stage.Enhancements) {
    router.push({ name: 'site-onboarding-enhancements', params: {slug: tenantSlug.value}})
    stage.value = newStage;
  } else {
    console.error('Unsupported Stage', newStage);
  }
}
</script>
<template>
  <section class="is-full-width columns">
    <SiteOnboardingViewModal />
    <div class="column is-3">
      <div class="card clickable" @click="updateStage(Stage.ProfileSurvey)">
        <div class="card-content">
          <i
            :class="{
              'fa-solid fa-square-check is-checked': profile.surveyComplete === true,
              'fa-regular fa-square': profile.surveyComplete === false,
            }"></i>
          Onboarding Survey
        </div>
      </div>
      <div
        :class="{
          'clickable': profile.surveyComplete === true,
        }"
        class="card" @click="updateStage(Stage.SiteCreation)">
        <div v-if="initialSite === null" class="card-content">
          <i class="fa-regular fa-square is-checked"></i>
          Create Site
        </div>
        <div v-else class="card-content">
          <i class="fa-solid fa-square-check is-checked"></i>
          Create Site
        </div>
      </div>
      <div
        :class="{ 'clickable': initialSite !== null }"
        class="card" @click="updateStage(Stage.NetworkTrafficTap)">
        <div v-if="false" class="card-content indent">
          <i class="fa-solid fa-square-check is-checked"></i>
          Site Network Intake
        </div>
        <div v-else class="card-content indent">
          <i class="fa-solid fa-circle is-checked" v-if="stage === Stage.NetworkTrafficTap"></i>
          <i class="fa-regular fa-circle is-checked" v-else></i>
          Site Network Intake
        </div>
      </div>
      <div
        :class="{ 'clickable': initialSite !== null }"
        class="card" @click="updateStage(Stage.IncidentResponse)">
        <div v-if="false" class="card-content indent">
          <i class="fa-solid fa-square-check is-checked"></i>
          Notifications
        </div>
        <div v-else class="card-content indent">
          <i class="fa-solid fa-circle is-checked" v-if="stage === Stage.IncidentResponse"></i>
          <i class="fa-regular fa-circle is-checked" v-else></i>
          Notifications
        </div>
      </div>
      <div
        :class="{ 'clickable': initialSite !== null }"
        class="card" @click="updateStage(Stage.NextGenAndXDRResponse)">
        <div v-if="false" class="card-content indent">
          <i class="fa-solid fa-square-check is-checked"></i>
          Automated Response
        </div>
        <div v-else class="card-content indent">
          <i class="fa-solid fa-circle is-checked" v-if="stage === Stage.NextGenAndXDRResponse"></i>
          <i class="fa-regular fa-circle is-checked" v-else></i>
          Automated Response
        </div>
      </div>
      <div
        :class="{ 'clickable': initialSite !== null }"
        class="card" @click="updateStage(Stage.Enhancements)">
        <div v-if="false" class="card-content indent">
          <i class="fa-solid fa-square-check is-checked"></i>
          Forensic Enhancements
        </div>
        <div v-else class="card-content indent">
          <i class="fa-solid fa-circle is-checked" v-if="stage === Stage.Enhancements"></i>
          <i class="fa-regular fa-circle is-checked" v-else></i>
          Forensic Enhancements
        </div>
      </div>
      <div
        :class="{ 'clickable': initialSite !== null }"
        class="card" @click="router.push({name: 'customer-operator-access', params: {customerSlug: tenantSlug}})">
        <div v-if="false" class="card-content ident">
          <i class="fa-solid fa-square-check is-checked"></i>
          Operator Access
        </div>
        <div v-else class="card-content indent">
          <i class="fa-regular fa-circle is-checked"></i>
          Operator Access
        </div>
      </div>
    </div>
    <div class="column is-9">
      <SiteOnboardingProfileSurvey v-show="stage == Stage.ProfileSurvey" />
      <SiteOnboardingSiteCreation v-show="stage == Stage.SiteCreation || stage == Stage.SiteReview" />
      <SiteOnboardingNetworkTrafficTap v-show="stage == Stage.NetworkTrafficTap" />
      <SiteOnboardingIncidentResponse v-show="stage == Stage.IncidentResponse" />
      <SiteOnboardingNextGenAndXDRResponse v-show="stage == Stage.NextGenAndXDRResponse" />
      <SiteOnboardingEnhancements v-show="stage == Stage.Enhancements" />
    </div>
  </section>
</template>
<style lang="scss" scoped>
.clickable {
  cursor: pointer;
}
.is-hoverable {
  cursor: pointer;
}
.card-content .table td {
  padding: 0.5rem 0;
}
.card-content.indent {
  margin-left: 2rem;
}
</style>