<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAuthenStore } from '@/stores/authen';
import { useConfigStore } from '@/stores/config';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import router from '@/router';

const authenStore = useAuthenStore();
const { authenMessage } = storeToRefs(authenStore);

const passwordResetScheme = yup.object().shape({
  email: yup.string().email().required(),
});

async function handlePasswordReset(values: any): Promise<void> {
  await authenStore.passwordResetInit(values['email']);
  if (authenMessage.value.hasError === false) {
    router.push({'name': 'password-reset-submit'})
    return;
  }
}
</script>
<template>
  <section class="is-full-width authen-background">
    <div class="columns">
      <div class="column is-half">
      </div>
      <div class="column is-half">
        <section class="authen-window">
          <h1 class="title"> Personam.ai </h1>
          <h2 class="subtitle"> Password Reset </h2>
          <h3 v-show="authenMessage.hasError === true" class="has-text-danger"> {{ authenMessage.message }} </h3>
          <Form @submit="handlePasswordReset" :validation-schema="passwordResetScheme">
            <div class="field">
              <label class="label"> E-Mail </label>
              <div class="control has-icons-left has-icons-right">
                <Field class="input" type="email" placeholder="jfansler@personam.dev" name="email" />
                <span class="icon is-small is-left">
                  <i class="fas fa-envelope"></i>
                </span>
                <span class="icon is-small is-right">
                  <i class="fas fa-check"></i>
                </span>
              </div>
              <ErrorMessage name="email" >
                <p class="help is-danger"> Invalid Email </p>
              </ErrorMessage>
            </div>
            <div class="field is-grouped">
              <div class="control">
                <button class="button is-primary"> Reset Password </button>
              </div>
              <div class="control">
                <router-link :to="{'name': 'login'}" class="button is-secondary"> Return to Login </router-link>
              </div>
            </div>
          </Form>
        </section>
      </div>
    </div>
  </section>
</template>