<script lang="ts" setup>
import type { Site } from '@/stores/tenancy';
import { type Operator, useSiteOperatorStore } from '@/stores/site-operator';
import { onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';

const siteOperatorStore = useSiteOperatorStore();
const { currentOperator, allSites } = storeToRefs(siteOperatorStore);
interface OperatorAccess {
  operator: Operator,
  site: Site,
  has_access: boolean,
}
const accessElements = ref<OperatorAccess[]>([])
async function rebuildAccessTable(): Promise<void> {
  if (currentOperator.value == null || currentOperator.value == undefined) {
    return;
  }
  accessElements.value.splice(0, accessElements.value.length);
  const operator_site_assoc: string[] = currentOperator.value.sites.reduce((acc: string[], site: Site) => {
    acc.push(site.guid);
    return acc;
  }, []);
  for(var idx = 0; idx < allSites.value.length; idx++) {
    const element = {
      operator: currentOperator.value,
      site: allSites.value[idx],
      has_access: operator_site_assoc.indexOf(allSites.value[idx].guid) > -1,
    } as OperatorAccess;
    accessElements.value.push(element);
  }
}
watch(currentOperator, async () => {
  await rebuildAccessTable();
})
onMounted(async () => {
  await rebuildAccessTable();
})
async function updateOperatorSites(ae: OperatorAccess): Promise<void> {
  ae.has_access = !ae.has_access; 
  const operator_sites = accessElements.value.reduce((acc: Site[], ae: OperatorAccess) => {
    if (ae.has_access) {
      acc.push(ae.site);
    }
    return acc;
  }, [])
  siteOperatorStore.updateCurrentOperator('sites', operator_sites);
}
async function selectAll(): Promise<void> {
  siteOperatorStore.updateCurrentOperator('sites', allSites.value);
  await rebuildAccessTable();
}
</script>
<template>
  <div class="field is-horizontal is-left">
    <div class="field-label is-normal has-text-left">
      <label class="label"> Customer wide Site Access List </label>
    </div>
    <div class="control">
      <button @click="selectAll()" class="button is-primary"> Select All </button>
    </div>
  </div>
  <table class="table hover is-bordered is-fullwidth">
    <tbody>
      <tr v-for="ae in accessElements">
        <td width="10%" class="has-text-centered">
          <div class="field">
            <div class="control">
              <input type="checkbox" @click="updateOperatorSites(ae)" :checked="ae.has_access">
            </div>
          </div>
        </td>
        <td> {{ ae.site.name }} </td>
      </tr>
    </tbody>
  </table>
</template>