<template>
  <div class="integration-box" >
    <div class="box">
      <div class="media">
        <div class="media-left">
          <figure class="image">
            <!-- https://www.servicenow.com/company/servicenow-logo.html -->
            <!-- Service Now SVG was created by Marketing from PNG Asset from the above link -->
            <svg
              version="1.1"
              id="svg2"
              width="110"
              viewBox="0 0 288 52.040001"
              sodipodi:docname="sn-logo-color.eps"
              xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
              xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:svg="http://www.w3.org/2000/svg">
              <defs
                id="defs6" />
              <g
                id="g8"
                inkscape:groupmode="layer"
                inkscape:label="ink_ext_XXXXXX"
                transform="matrix(1.3333333,0,0,-1.3333333,0,42.04)">
                <g
                  id="g10"
                  transform="scale(0.1)">
                  <path
                    d="m 519.746,218.125 c -23.457,0 -43.445,-8.289 -59.516,-21.367 v 19.187 H 405.926 V 4.45313 h 56.476 V 139.629 c 7.821,10.906 27.368,26.168 51.262,26.168 8.508,0 16.293,-0.621 22.664,-3.395 v 54.274 c -5.398,0.945 -10.937,1.449 -16.582,1.449"
                    style="fill:#100f0d;fill-opacity:1;fill-rule:evenodd;stroke:none"
                    id="path12" />
                  <path
                    d="M 27.3672,68.9883 C 41.7031,55.9102 63.8594,48.0625 85.1445,48.0625 c 15.2075,0 26.9335,7.4141 26.9335,17.875 0,31.8281 -102.08581,20.4961 -102.08581,88.5235 0,40.551 39.09761,65.848 80.80471,65.848 27.3671,0 57.3441,-10.465 71.2421,-21.368 l -26.496,-40.992 c -10.863,7.848 -25.199,14.391 -40.4063,14.391 -15.6367,0 -28.6719,-6.106 -28.6719,-17.004 0,-27.477 102.0942,-16.578 102.0942,-89.8321 C 168.559,24.9492 129.023,0.0898438 84.7148,0.0898438 55.6016,0.0898438 24.7617,10.1211 0,28.4375 l 27.3672,40.5508"
                    style="fill:#100f0d;fill-opacity:1;fill-rule:evenodd;stroke:none"
                    id="path14" />
                  <path
                    d="m 237.16,137.453 c 2.606,19.192 21.723,37.067 45.18,37.067 23.023,0 39.965,-18.317 41.703,-37.067 z m 144.66,-25.289 c 0,58.867 -41.265,108.145 -99.48,108.145 -62.555,0 -102.524,-51.457 -102.524,-110.325 0,-66.2848 47.352,-109.89025 110.34,-109.89025 32.582,0 66.035,13.51565 86.457,37.93755 l -32.148,32.2695 c -9.992,-10.9024 -29.11,-24.4219 -53,-24.4219 -29.977,0 -53.871,20.5 -56.914,50.1445 H 380.52 c 0.867,4.8006 1.3,10.4726 1.3,16.1406"
                    style="fill:#100f0d;fill-opacity:1;fill-rule:evenodd;stroke:none"
                    id="path16" />
                  <path
                    d="m 670.926,86.8711 57.777,129.0739 h 58.652 L 690.473,4.45313 H 651.375 L 554.5,215.945 h 58.648 L 670.926,86.8711"
                    style="fill:#100f0d;fill-opacity:1;fill-rule:evenodd;stroke:none"
                    id="path18" />
                  <path
                    d="m 834.773,315.258 c 20.426,0 36.93,-15.699 36.93,-36.192 0,-20.5 -16.504,-36.195 -36.93,-36.195 -20.414,0 -36.921,15.695 -36.921,36.195 0,20.493 16.507,36.192 36.921,36.192"
                    style="fill:#100f0d;fill-opacity:1;fill-rule:evenodd;stroke:none"
                    id="path20" />
                  <path
                    d="m 806.539,4.45313 h 56.477 V 215.945 H 806.539 V 4.45313"
                    style="fill:#100f0d;fill-opacity:1;fill-rule:evenodd;stroke:none"
                    id="path22" />
                  <path
                    d="m 1096.52,47.625 c -24.76,-33.1367 -56.04,-47.53125 -95.57,-47.53125 -65.169,0 -112.954,48.83985 -112.954,109.88625 0,61.493 49.09,110.329 113.384,110.329 36.49,0 69.08,-17.008 88.62,-42.301 l -39.96,-35.32 c -11.3,14.828 -28.24,25.292 -48.66,25.292 -32.579,0 -56.911,-25.73 -56.911,-58 0,-33.1362 23.894,-57.5542 58.211,-57.5542 23.03,0 41.27,13.9531 50.4,27.9023 l 43.44,-32.7031"
                    style="fill:#100f0d;fill-opacity:1;fill-rule:evenodd;stroke:none"
                    id="path24" />
                  <path
                    d="m 1249.07,137.453 c -1.73,18.75 -18.68,37.067 -41.7,37.067 -23.46,0 -42.58,-17.875 -45.18,-37.067 z m 52.57,-99.4217 c -20.42,-24.4219 -53.87,-37.93755 -86.45,-37.93755 -62.99,0 -110.34,43.60545 -110.34,109.89025 0,58.868 39.96,110.325 102.52,110.325 58.21,0 99.48,-49.278 99.48,-108.145 0,-5.668 -0.43,-11.34 -1.3,-16.1406 h -145.97 c 3.04,-29.6445 26.94,-50.1445 56.91,-50.1445 23.9,0 43.01,13.5195 53.01,24.4219 l 32.14,-32.2695"
                    style="fill:#100f0d;fill-opacity:1;fill-rule:evenodd;stroke:none"
                    id="path26" />
                  <path
                    d="M 1329.3,4.45313 V 215.945 h 54.3 v -17.004 c 16.08,13.079 36.06,21.368 59.52,21.368 29.11,0 55.6,-13.079 72.98,-34.016 13.91,-16.57 21.72,-37.938 21.72,-74.129 V 4.45313 h -56.47 V 116.523 c 0,20.93 -4.78,31.832 -12.6,39.68 -7.39,7.414 -18.68,11.777 -31.71,11.777 -23.9,0 -43.44,-15.265 -51.26,-26.167 V 4.45313 h -56.48"
                    style="fill:#100f0d;fill-opacity:1;fill-rule:evenodd;stroke:none"
                    id="path28" />
                  <path
                    d="m 1682.52,34.8945 c -36.55,0 -61.57,27.5235 -61.57,61.5664 0,34.0591 25.02,62.0431 61.57,62.0431 36.57,0 61.57,-27.984 61.57,-62.0431 0,-34.0429 -25,-61.5664 -61.57,-61.5664 m 0.72,185.4105 c -67.48,0.39 -123.29,-54.828 -123.86,-122.5628 -0.29,-35.8555 14.63,-68.2266 38.68,-90.98829 8.75,-8.27344 22.27,-9.07813 31.75,-1.67969 14.02,10.94528 32.02,17.46488 52.71,17.46488 20.69,0 38.69,-6.5235 52.71,-17.46488 9.57,-7.46094 23.14,-6.50391 31.94,1.85937 23.7,22.52731 38.5,54.42191 38.5,89.76561 0,68.0198 -54.76,123.2148 -122.43,123.6058"
                    style="fill:#6ac63f;fill-opacity:1;fill-rule:evenodd;stroke:none"
                    id="path30" />
                  <path
                    d="m 1917.42,4.45313 h -42.06 L 1791.49,215.945 h 56.39 l 45.98,-120.863 45.09,120.863 h 47.13 l 44.68,-120.863 46.39,120.863 h 56.39 L 2049.67,4.45313 h -42.06 L 1962.52,124.883 1917.42,4.45313"
                    style="fill:#100f0d;fill-opacity:1;fill-rule:evenodd;stroke:none"
                    id="path32" />
                  <path
                    d="m 2129.43,31.9141 h 5.78 c 2.14,0 3.87,1.8203 3.87,4.1015 0,2.3125 -1.73,4.1055 -3.87,4.1055 h -5.78 z m 4.77,-3.6133 h -4.77 v -8.9453 h -4.03 v 24.5508 h 9.81 c 4.39,0 7.89,-3.5079 7.89,-7.8907 0,-3.1601 -1.99,-5.9609 -4.87,-7.1172 l 5.58,-9.5429 h -4.42 l -5.19,8.9453"
                    style="fill:#100f0d;fill-opacity:1;fill-rule:nonzero;stroke:none"
                    id="path34" />
                  <path
                    d="m 2133.67,57.5391 c -14.54,0 -26.33,-11.7852 -26.33,-26.3282 0,-14.539 11.79,-26.32809 26.33,-26.32809 14.54,0 26.33,11.78909 26.33,26.32809 0,14.543 -11.79,26.3282 -26.33,26.3282 z m 0,-3.5977 c 12.53,0 22.73,-10.1953 22.73,-22.7305 0,-12.5312 -10.2,-22.73043 -22.73,-22.73043 -12.53,0 -22.73,10.19923 -22.73,22.73043 0,12.5352 10.2,22.7305 22.73,22.7305"
                    style="fill:#100f0d;fill-opacity:1;fill-rule:nonzero;stroke:none"
                    id="path36" />
                </g>
              </g>
            </svg>
          </figure>
        </div>
        <div class="media-content">
          Integrate
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.box {
  padding-top: .75rem;
  padding-bottom: 0;
}
.media-left {
  width: 120px;
  padding-top: 1.25rem;
}
.media-content {
  font-size: 20px;
  padding-top: 14px;
  padding-left: 0px;
}
</style>