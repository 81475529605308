import { defineStore } from "pinia";
import type { Bureau } from './bureau';
import { AxiosClient } from './axios-client';
export enum AccessScope {
  Personam = 'Personam',
  Bureau = 'Bureau',
  None = 'None',
};
export type OperatorAccess = {
  guid: string,
  created: Date,
  updated: Date,
  name: string,
  email: string,
  scope: AccessScope,
  email_notification: boolean,
  sms_notification: boolean,
  phone_number: string,
  bureau: Bureau,
};
export type CountryInfo = {
  name: string,
  code: string,
  value: string,
};
export const useAccessStore = defineStore('access', {
  state: () => ({
    _accessBureau: [] as OperatorAccess[],
    _accessBureauOperatorCache: new Map<string, OperatorAccess[]>,
    _accessOperatorBureauCache: new Map<string, OperatorAccess[]>,
  }),
  getters: {
    accessBureau: (state) => (bureau: Bureau): OperatorAccess | null => {
      return state._accessBureau.find((oa: OperatorAccess) => oa.guid === bureau.guid) || null;
    },
    accessBureauList: (state): OperatorAccess[] => { return state._accessBureau; },
    accessBureauOperatorList: (state) => (oa: OperatorAccess): OperatorAccess[] => {
      return state._accessBureauOperatorCache.get(oa.bureau.guid) || [];
    },
    accessOperatorBureauList: (state) => (oa: OperatorAccess): OperatorAccess[] => {
      return state._accessOperatorBureauCache.get(oa.guid) || [];
    },
    countryInfo: (): CountryInfo[] => {
      return [
        { name: 'United States', code: 'US', value: '+1' },
        { name: 'Japan', code: 'JP', value: '+81' },
      ]
    },
  },
  actions: {
    async loadBureau(): Promise<OperatorAccess[]> {
      if (this._accessBureau.length > 0) {
        return this._accessBureau;
      }
      const url = `/access/list-bureau`;
      this._accessBureau = (await AxiosClient.get(url)).data;
      return this._accessBureau;
    },
    async loadOperatorByBureau(oa: OperatorAccess): Promise<OperatorAccess[]> {
      // const cache = this._accessBureauOperatorCache.get(oa.bureau.guid);
      // if (cache) {
      //   return cache;
      // }
      const url = `/access/list-operator-by-bureau/${oa.bureau.guid}`;
      const resp = await AxiosClient.get(url);
      this._accessBureauOperatorCache.set(oa.bureau.guid, resp.data);
      return resp.data;
    },
    async loadOperatorBureauByOperator(oa: OperatorAccess): Promise<OperatorAccess[]> {
      // const cache = this._accessOperatorBureauCache.get(oa.guid);
      // if (cache) {
      //   return cache;
      // }
      const url = `/access/list-bureau-by-operator/${oa.guid}`;
      const resp = await AxiosClient.get(url);
      this._accessOperatorBureauCache.set(oa.guid, resp.data);
      return resp.data;
    },
    async createOperator(oa: OperatorAccess[]): Promise<OperatorAccess[]> {
      const results: OperatorAccess[] = [];
      for (const o of oa) {
        const url = `/access/bureau-profile-create/${o.bureau.guid}`;
        const data = {
          name: o.name,
          email: o.email,
        };
        const resp = await AxiosClient.post(url, data);
        if (resp.status !== 201) {
          throw new Error(`Failed to create operator: ${resp.status}`);
        }
        const update_url = `/access/bureau-profile-update/${o.bureau.guid}/${resp.data.guid}`;
        const update_profile = {
          name: o.name,
          email: o.email,
          access_scope: o.scope,
          email_notification: o.email_notification,
          sms_notification: o.sms_notification,
          phone_number: o.phone_number,
        }
        const update_resp = await AxiosClient.put(update_url, update_profile);
        if (update_resp.status !== 202) {
          throw new Error(`Failed to update operator: ${update_resp.status}`);
        }
      }
      return results;
    },
    async updateOperator(oa: OperatorAccess[]): Promise<OperatorAccess[]> {
      const results: OperatorAccess[] = [];
      for (const o of oa) {
        const update_url = `/access/bureau-profile-update/${o.bureau.guid}/${o.guid}`;
        const update_profile = {
          name: o.name,
          email: o.email,
          access_scope: o.scope,
          email_notification: o.email_notification,
          sms_notification: o.sms_notification,
          phone_number: o.phone_number
        }
        const update_resp = await AxiosClient.put(update_url, update_profile);
        if (update_resp.status !== 202) {
          throw new Error(`Failed to update operator: ${update_resp.status}`);
        }
      }
      return results;
    },
    async deleteOperator(oa: OperatorAccess[]): Promise<void> {
      for (const o of oa) {
        const url = `/access/bureau-profile-delete/${o.bureau.guid}/${o.guid}`;
        const resp = await AxiosClient.delete(url);
        if (resp.status !== 204) {
          throw new Error(`Failed to delete operator: ${resp.statusText}`);
        }
      }
    }
  },
});
