<script setup lang="ts">
import { onMounted, ref, defineProps, type PropType } from "vue";
import { useTenancyStore, type Customer } from "@/stores/tenancy";
import router from "@/router";
const props = defineProps<{ tenants?: Customer[] }>();
const tenancyStore = useTenancyStore();
function customerClick(index: number) {
  tenancyStore.setCurrentCustomer(index);
  router.push({ path: `/site/${tenancyStore.current_customer?.slug}` });
}
function siteClick(
  customerSlug: string,
  slug: string,
  index: number,
  siteIndex: number
) {
  tenancyStore.setCurrentSite(index, siteIndex);
  tenancyStore.setCurrentCustomer(index);
  router.push({ path: `/site/${customerSlug}/${slug}/${tenancyStore.current_state}` });
}
function operatorAccessClick(customerSlug: string, index: number) {
  tenancyStore.setCurrentCustomer(index);
  router.push({ path: `/site/${customerSlug}/operator-access` });
}
function notificationClick(customerSlug: string, index: number) {
  tenancyStore.setCurrentCustomer(index);
  router.push({ path: `/site/${customerSlug}/notification` });
}
function createSiteClick(customerSlug: string, index: number) {
  tenancyStore.setCurrentCustomer(index);
  router.push({ path: `/site/${customerSlug}/site-creation` });
}
</script>
<template>
  <aside v-if="props.tenants != undefined" class="menu sidebar-scroll">
    <p class="menu-label"> By Customer </p>
    <ul class="menu-list">
      <li v-for="(tenant, index) in props.tenants" :key="tenant.guid">
        <a
          :class="{
            'is-active':
              tenancyStore.current_customer?.guid == tenant.guid &&
              router.currentRoute.value.matched[0].path ==
                '/site/:customerSlug',
          }"
          @click.stop.prevent="customerClick(index)"
          ><span class="tag is-danger is-small mr-1" v-if="tenant.open_alert_count != '0'">{{
            tenant.open_alert_count
          }}</span>
          {{ tenant.name }}</a
        >
        <ul>
          <li>
            <p class="menu-label"> Administration </p>
            <ul>
              <li>
                <a :class="{ 
                  'is-active':
                    tenancyStore.current_customer?.guid == tenant.guid &&
                    router.currentRoute.value.matched[0].path ==
                      '/site/:customerSlug/notification',
                 }" @click.stop.prevent="notificationClick(tenant.slug, index)"> Notifications </a>
              </li>
              <li>
                <a :class="{ 
                  'is-active':
                    tenancyStore.current_customer?.guid == tenant.guid &&
                    router.currentRoute.value.matched[0].path ==
                      '/site/:customerSlug/operator-access',
                 }" @click.stop.prevent="operatorAccessClick(tenant.slug, index)"> Operator Access </a>
              </li>
              <li>
                <a :class="{ 
                  'is-active':
                    tenancyStore.current_customer?.guid == tenant.guid &&
                    router.currentRoute.value.matched[0].path ==
                      '/site/:customerSlug/site-creation',
                 }" @click.stop.prevent="createSiteClick(tenant.slug, index)"> Site Creation </a>
              </li>
            </ul>
          </li>
          <li>
            <p class="menu-label"> by Sites </p>
            <ul>
              <li v-for="(site, siteIndex) in tenant.sites" :key="site.guid">
                <a
                  :class="{
                    'is-active':
                      tenancyStore.current_site?.guid == site.guid &&
                      router.currentRoute.value.matched[0].path.includes('/site/:customerSlug/:siteSlug')
                  }"
                  @click.stop.prevent="
                    siteClick(tenant.slug, site.slug, index, siteIndex)
                  "
                  ><span class="tag is-danger is-small mr-1" v-if="site.open_alert_count != 0">{{
                    site.open_alert_count
                  }}</span
                  >{{ site.name }}</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <!-- <p class="menu-label">Billing</p> -->
    <!-- <p class="menu-label">Operator Settings</p> -->
  </aside>
</template>

<style lang="sass">

.sidebar-scroll
  height: 100vh
  overflow: auto
  scrollbar-width: none
</style>