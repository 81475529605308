<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useProfileStore, SurveySelfInterest, type Survey } from '@/stores/profile';
import { Form, Field, ErrorMessage, useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import router from '@/router';
import { onMounted } from 'vue';

const profileStore = useProfileStore();
const { profile, survey } = storeToRefs(profileStore);
const surveySchema = yup.object().shape({
  selfInterest: yup.string().notRequired(),
  companySize: yup.string().required().min(1),
  incidentManagement: yup.string().required().min(1),
  networkUsers: yup.string().required().min(1),
  networkDevices: yup.string().required().min(1),
});
async function handleSurvey(values: any): Promise<void> {
  values['selfInterest'] = values['selfInterest'] || 'MyCompany';
  values['selfInterest'] = SurveySelfInterest[values['selfInterest'] as keyof typeof SurveySelfInterest],
  await profileStore.completeSurvey(values as Survey);
  router.push({'name': 'site-onboarding-site-creation'});
};
onMounted(async () => {
  await profileStore.loadProfile();
  await profileStore.loadSurvery();
})
</script>
<template>
  <div class="card">
    <div class="card-content">
      <section class="columns">
        <div class="column is-8">
          <div class="content">
            <h1 class="title"> Fill out Survey </h1>
            <h2 class="subtitle is-6"> Personam.ai </h2>
            <div class="content">
              <p>
                Hi {{ profile.name }}&#33; Personam helps organizations of all shapes and sizes with the
                <span class="has-text-weight-bold"> {{  profile.industry }} </span>industry, 
                and we are excited for the opportunity to help you. To help us provide you with the most appropriate 
                software and configuration options to address your unique needs, we need you to respond to the following 
                questions.
              </p>
            </div>
            <Form @submit="handleSurvey" :validation-schema="surveySchema" :initial-values="survey">
              <div v-show="profile.industry === 'Managed Service Provider'" class="field">
                <label class="label">
                  1. Are you looking to provision a monitoring solution for your organization's network, or for one of your customers'?
                </label>
                <div class="control">
                  <label class="radio">
                    <Field
                      :disabled="profile.surveyComplete === true"
                      type="radio" name="selfInterest" value="MyCompany"/>
                      My Organization 
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <label class="radio">
                    <Field
                      :disabled="profile.surveyComplete === true"
                      type="radio" name="selfInterest" value="OurClient"> </Field>
                      One or more of my clients
                  </label>
                </div>
                <ErrorMessage name="selfInterest">
                  <p class="help is-danger"> Please select an option above </p>
                </ErrorMessage>
              </div>
              <div class="field">
                <label class="label">
                  <span v-if="profile.industry === 'Managed Service Provider'"> 2. </span>
                  <span v-else> 1. </span>
                  How many dedicated cyber security professionals do you employ?
                </label>
                <Field
                  :disabled="profile.surveyComplete === true"
                  class="input" type="text" placeholder="10-15" name="companySize" />
              </div>
              <ErrorMessage name="companySize">
                <p class="help is-danger"> Please provide a number between one and infinity </p>
              </ErrorMessage>
              <div class="field">
                <label class="label">
                  <span v-if="profile.industry === 'Managed Service Provider'"> 3. </span>
                  <span v-else> 2. </span>
                  What are you currently using for Incident Management?
                </label>
                <Field
                  :disabled="profile.surveyComplete === true"
                  class="input" type="text" placeholder="Splunk, Elastic, Service Now, Kaseya VSA" name="incidentManagement" />
              </div>
              <ErrorMessage name="incidentManagement">
                <p class="help is-danger"> Please input the incident management solution you're using </p>
              </ErrorMessage>
              <div class="field">
                <label class="label">
                  <span v-if="profile.industry === 'Managed Service Provider'"> 4. </span>
                  <span v-else> 3. </span>
                  Approx how many users are on the network we are about to deploy to?
                </label>
                <Field
                  :disabled="profile.surveyComplete === true"
                  class="input" type="text" placeholder="100+" name="networkUsers" />
              </div>
              <ErrorMessage name="networkUsers">
                <p class="help is-danger"> Please provide a number between one and infinity </p>
              </ErrorMessage>
              <div class="field">
                <label class="label">
                  <span v-if="profile.industry === 'Managed Service Provider'"> 5. </span>
                  <span v-else> 4. </span>
                  Approx how many devices on the network we are about to deploy to?
                </label>
                <Field
                  :disabled="profile.surveyComplete === true"
                  class="input" type="text" placeholder="100+" name="networkDevices" />
              </div>
              <ErrorMessage name="networkDevices">
                <p class="help is-danger"> Please provide a number between one and infinity </p>
              </ErrorMessage>
              <div class="buttons is-right">
                <button class="button is-primary"> Next Page </button>
              </div>
              <hr/>
              <p style="color:#545454;" class="has-text-centered"> OR </p>
              <hr/>
              <div class="columns">
                <div class="column">
                  <p class="content" style="padding-top: 8px;">
                    If you prefer to speak with a human representative
                  </p>
                </div>
                <div class="column">
                  <div class="buttons is-right">
                    <a href="https://calendar.app.google/v87Arhof3cr4XWJx9" target="_blank" class="button is-secondary"> Let us call you </a>
                  </div>
                </div>
              </div>
            </Form>  
          </div>
        </div>
      </section>
    </div>
  </div>
</template>