import { defineStore } from 'pinia';
import { AxiosClient } from './axios-client';
import type { Alert } from './alert';

export enum ClientType {
  User = 'User',
  Device = 'Device'
}
export type Client = {
  guid: string,
  name: string,
  email: string,
  clientType: ClientType,
}
export const useClientStore = defineStore('client', {
  state: () => ({
    _client: {
      guid: 'noop',
      name: 'noop',
      email: 'noop',
    } as Client,
  }),
  getters: {
    client: (state): Client => { return state._client; },
  },
  actions: {
    async loadClientByAlert(alert: Alert): Promise<Client> {
      if (this._client.guid === alert.client.guid) {
        return this._client;
      }
      const url = `/client/by-alert/${alert.guid}`
      const resp = await AxiosClient.get(url);
      this._client = resp.data;
      return this._client;
    }
  }
});