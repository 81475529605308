<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { storeToRefs } from 'pinia';
import { useConfigStore } from '@/stores/config';
import { useTenantStore } from '@/stores/tenant';
import { useProfileStore } from '@/stores/profile';
import type { Detector } from '@/stores/detector';

const configStore = useConfigStore();
const profileStore = useProfileStore();
const tenantStore = useTenantStore();

const { initialSite } = storeToRefs(profileStore);

const detectors = ref<Detector[]>([]);
onMounted(async () => {
  // Obtain state to get the detector_id of the newly created site
  await profileStore.loadInitialSite();
  const site = await tenantStore.loadTenantByGuid(initialSite.value!.guid)
  detectors.value = await tenantStore.loadDetectors(site);
})
const sensorDownloadUrl = computed(() => {
  if (detectors.value.length == 1) {
    const url = `${configStore.apiUrl}/ls/sensor-installer/${detectors.value[0].guid}`
    return url;
  }
  return '';
})
</script>
<template>
  <div class="card events-card">
    <div class="card-content">
      <div style="margin-top: -0.8rem;" class="columns">
        <div class="column is-8">
          <p class="subtitle is-5"> Detected networks from previously installed Sensors </p>
        </div>
        <div class="column is-4">
          <div class="buttons is-fullwidth is-right">
            <a class="button is-primary" :href="sensorDownloadUrl" >
              <span class="icon">
                <i class="fa-solid fa-download"></i>
              </span>
              <span> Download Sensor Agent </span>
            </a>
          </div>
        </div>
      </div>
      <div class="media">
        <div class="media-left">
          <figure class="image is-48x48">
            <img src="/public/personam-alert.svg" alt="Placeholder image">
          </figure>
        </div>
        <div class="media-content">
          <p class="title is-4" style="padding-top:10px;"> Personam.AI Sensor Agent </p>
          <p> Personam.AI provides the Sensor Agent for you to install on a Windows VM somewhere inside your network. Once installed, configure a SPAN/TAP interface to forward network traffic to one of the network adapters and the Sensor Agent will transmite nessicary information to our Detector in the Cloud. </p>
        </div>
      </div>
      <footer class="card-footer">
      </footer>
    </div>
  </div>
</template>