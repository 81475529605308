import { defineStore } from "pinia";

export enum MessageStatus {
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
}
export class Message {
  status: MessageStatus;
  message: String;
  ts: Date;
  constructor(status: MessageStatus, message: String) {
    this.status = status;
    this.message = message;
    this.ts = new Date();
  }
}
export class ErrorMessage extends Error {
  code: string;
  constructor(code: string, message: string) {
    super(message);
    this.code = code;
  }
};

export const useMessageStore = defineStore('message', {
  state: () => ({
    _messages: [] as Message[],
  }),
  getters: {
    messages: (state): Message[] => { return state._messages; },
  },
  actions: {
    async pushMessage(message: Message): Promise<void> {
      this._messages.unshift(message);
    },
    async handle(message: Message|ErrorMessage): Promise<void> {
      if (Object.prototype.hasOwnProperty.call(message, "status")) {
        this._messages.unshift(message as Message);
      } else if (Object.prototype.hasOwnProperty.call(message, "message")) {
        this._messages.unshift(new Message(MessageStatus.Warning, message.message));
      } else {
        console.error(message)
        this._messages.unshift(new Message(MessageStatus.Danger, "System Error - Contact Customer Support"));
      }
    }
  }
});