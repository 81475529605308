<script setup lang="ts">
import SiteOnboardingViewModal from '../../components/site/onboarding/SiteOnboardingViewModal.vue'

import CustomerSiteCreation from '../../components/tenant/customer/SiteCreation.vue';
import CustomerSiteIntakeConfig from '../../components/tenant/customer/SiteIntakeConfig.vue';

import { onMounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import router from '@/router'; 

import { useTenancyStore, type Customer } from "@/stores/tenancy";
import { useSiteCreationStore } from '@/stores/site-creation';
const tenancyStore = useTenancyStore();
const { current_customer } = storeToRefs(tenancyStore);
const siteCreationStore = useSiteCreationStore();
const { siteSlug } = storeToRefs(siteCreationStore);

enum Stage {
  SiteCreation = 'site-creation',
  SiteIntakeConfig = 'site-intake-config',
  SiteOperatorAccess = 'site-operator-access',
};
const stage = ref<Stage | null>(null);
const siteCreated = ref<boolean>(false);
async function syncViewToRouter(): Promise<void> {
  const routeName = router.currentRoute.value.name;
  if (routeName === 'customer-site-creation') {
    stage.value = Stage.SiteCreation;
  } else if (routeName === 'customer-site-creation-with-site-slug') {
    stage.value = Stage.SiteCreation;
  } else if (routeName === 'customer-site-intake-config') {
    stage.value = Stage.SiteIntakeConfig;
  } else {
    console.error("Route Not Handled:", routeName);
  }
}
async function updateStage(newStage: Stage): Promise<void> {
  if (newStage == Stage.SiteCreation) {
    if (siteSlug.value === null) {
      router.push({ name: 'customer-site-creation', params: {customerSlug: current_customer!.value!.slug}})
    } else {
      router.push({ name: 'customer-site-creation-with-site-slug', params: {customerSlug: current_customer!.value!.slug, siteSlug: siteSlug.value!}})
    }
  } else if (newStage == Stage.SiteIntakeConfig) {
    if (siteSlug.value === null) {
      return;
    }
    router.push({ name: 'customer-site-intake-config', params: {customerSlug: current_customer!.value!.slug, siteSlug: siteSlug.value!}})
  } else if (newStage == Stage.SiteOperatorAccess) {
    siteCreationStore.clearSite();
    router.push({ name: 'customer-operator-access', params: {customerSlug: current_customer!.value!.slug}})
  } else {
    console.error("Stage not handled:", newStage)
  }
}
onMounted(async () => {
  let _ = await tenancyStore.loadTenants();
  const customerSlug = router.currentRoute.value.params.customerSlug!;
  tenancyStore.setCurrentCustomerWithSlug(customerSlug as string);
  await syncViewToRouter();
});
</script>
<template>
  <section class="is-full-width columns">
    <SiteOnboardingViewModal />
    <div class="column is-3">
      <div
        :class="{'clickable': siteCreated}"
        class="card" @click="updateStage(Stage.SiteCreation)">
        <div class="card-content">
          <i class="fa-regular fa-square is-checked"></i>
          Site Creation
        </div>
      </div>
      <div
        class="card clickable" @click="updateStage(Stage.SiteIntakeConfig)">
        <div class="card-content ident">
          <i class="fa-regular fa-square is-checked"></i>
          Site Network Intake
        </div>
      </div>
      <div
        class="card clickable" @click="updateStage(Stage.SiteOperatorAccess)">
        <div class="card-content ident">
          <i class="fa-regular fa-square is-checked"></i>
          Operator Access
        </div>
      </div>
    </div>
    <div class="column is-9">
      <CustomerSiteCreation v-show="stage == Stage.SiteCreation"/> 
      <CustomerSiteIntakeConfig v-show="stage == Stage.SiteIntakeConfig" />
    </div>
  </section>
</template>
<style lang="scss" scoped>
.clickable {
  cursor: pointer;
}
.is-hoverable {
  cursor: pointer;
}
.card-content .table td {
  padding: 0.5rem 0;
}
.card-content.indent {
  margin-left: 2rem;
}
</style>