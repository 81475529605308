<template>
  <div class="integration-box">
    <div class="box">
      <div class="media">
        <div class="media-left">
          <span style="font-size:40px;" class="fa-solid fa-file"></span>
        </div>
        <div class="media-content">
          <p class="is-5">
            PCap Data Upload
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.media-content {
  font-size: 20px;
  padding-top: 7px;
  padding-left: 10px;
}
</style>