<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import router from '@/router';
import { storeToRefs } from 'pinia';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { useSiteCreationStore } from '@/stores/site-creation';
const siteCreationStore = useSiteCreationStore();
const { siteName, siteSlug } = storeToRefs(siteCreationStore);
import { useTenancyStore, type Site } from '@/stores/tenancy';
const tenancyStore = useTenancyStore();
const siteNameSchema = yup.object().shape({
  name: yup.string().required().min(5),
});
const siteNameInitialValues = ref<object>({
  name: null as string | null,
});
async function handleSiteCreate(values: any): Promise<void> {
  let result = await siteCreationStore.createSite(values['name']);
  if (result) {
    router.push({name: 'customer-site-intake-config', params: {customerSlug: tenancyStore.current_customer?.slug, siteSlug: siteSlug.value}})
  }
}
const hasSite = computed<boolean>(() => {
  if (siteCreationStore.siteName == null) {
    return false;
  }
  return true;
});
onMounted(async () => {
  await tenancyStore.loadTenants();
  const routeName = router.currentRoute.value.name;
  const customerSlug = router.currentRoute.value.params.customerSlug!;
  tenancyStore.setCurrentCustomerWithSlug(customerSlug as string);
  // Try to set site using name from store/browser memory
  if (siteName != null) {
    siteNameInitialValues.value = {
      'name': siteName.value,
    }
  }
  // Try to load site from url slug
  if (routeName === 'customer-site-creation-with-site-slug' && siteSlug !== null) {
    const routeSiteSlug = router.currentRoute.value.params.siteSlug;
    tenancyStore.setCurrentSiteWithSlug(customerSlug as string, routeSiteSlug as string);
    siteCreationStore.setSite(tenancyStore.current_site! as Site)
    siteNameInitialValues.value = {
      'name': siteSlug.value,
    }
  }
})
</script>
<template>
  <div class="card">
    <div style="padding-top: .75rem;" class="card-content">
      <div class="media">
        <div class="media-left">
          <figure class="image is-48x48">
            <img src="/public/personam-alert.svg" alt="Placeholder image">
          </figure>
        </div>
        <div class="media-content">
          <p class="title is-4" style="padding-top:10px;"> Site Creation </p>
        </div>
      </div>
      <hr/>
      <section class="columns">
        <div class="column is-8">
          <Form @submit="handleSiteCreate" :validation-schema="siteNameSchema" :initial-values="siteNameInitialValues">
            <div class="field">
              <div class="content">
                <p>
                  Let’s create a new Site!  A Site is a discrete organizational unit where Personam’s threat detection engine looks for behavioral abnormalities.  Because each threat detection engine is isolated to a single site, many customers opt to have their entire company under one Site.  However others treat different physical locations or organizational functions into different Sites.  We’ll manage access later, and please know that due to Personam’s multi-tenant architecture, you will be able to define which of your users has access to this site.
                </p>
              </div>
              <label class="label">Site Name</label>
              <div class="control">
                <Field
                  :disabled="hasSite"
                  class="input" type="text" placeholder="Gamma Brothers, Inc" name="name" />
              </div>
            </div>
            <ErrorMessage name="name">
              <p class="help is-danger">
                Site Name is required and must be at least 5 characters long.
              </p>
            </ErrorMessage>
            <div class="buttons is-right">
              <button class="button is-primary"> Create </button>
            </div>
            <hr/>
            <p style="color:#545454;" class="has-text-centered"> OR </p>
            <hr/>
            <div class="columns">
              <div class="column">
                <p class="content" style="padding-top: 8px;">
                  If you prefer to speak with a human representative
                </p>
              </div>
              <div class="column">
                <div class="buttons is-right">
                  <a href="https://calendar.app.google/v87Arhof3cr4XWJx9" target="_blank" class="button is-secondary"> Next Page </a>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div class="column is-6"></div>
      </section>
    </div>
  </div>
</template>