<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useSiteOnboardingModal } from '@/stores/siteOnboardingModal';

const siteOnboardingModal = useSiteOnboardingModal();
const { active: modalIsActive, component, title } = storeToRefs(siteOnboardingModal);
async function toggleClose() {
  await siteOnboardingModal.toggleClose();
}
</script>
<template>
  <div
    class="modal"
    :class="{ 'is-active': modalIsActive === true }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title"> {{ title }} </p>
        <button class="delete" aria-label="close" @click="toggleClose()"></button>
      </header>
      <section class="modal-card-body">
        <Component v-show="component !== null" :is="component?.component" :key="component?.routeName" />
      </section>
      <footer class="modal-card-foot is-right">
        <div class="container">
          <div class="buttons is-right">
            <button class="button is-secondary" @click="toggleClose()"> Close </button>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.modal-card {
  width: 1028px;
}
</style>