import { createRouter, createWebHistory } from 'vue-router';
// Authen
import LoginView from '../views/authen/LoginView.vue';
import SignUpView from '../views/authen/SignUpView.vue';
import PasswordResetInitView from '../views/authen/PasswordResetInitView.vue';
import PasswordResetSubmitView from '../views/authen/PasswordResetSubmitView.vue';
import PasswordResetConfirmView from '../views/authen/PasswordResetConfirmView.vue';
// Site UI
import SiteOnboardingView from '../views/SiteOnboardingView.vue';
import SiteAlert from '@/views/panel/SiteAlert.vue';
import SiteNotice from '@/views/panel/SiteNotice.vue';
import SitePersonam from '@/views/panel/SitePersonam.vue';
import SiteCollector from '@/views/panel/SiteCollector.vue'; // Status & Monitoring
// Customer
import CustomerTentantView from '@/views/panel/CustomerTentantView.vue';
import CustomerSettings from '@/views/customer/CustomerSettings.vue';
import CustomerNotification from '@/views/notification/CustomerNotification.vue';
import CustomerOperatorView from '@/views/customer/CustomerOperatorAccessView.vue';
import CustomerSiteCreationFlowView from '@/views/customer/CustomerSiteCreationFlowView.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Index',
      redirect: '/login',
    },
    // Authen
    {
      path: '/login',
      name: 'login',
      component: LoginView,
    },
    {
      path: '/sign-up',
      name: 'sign-up',
      component: SignUpView,
    },
    {
      path: '/password-reset-init',
      name: 'password-reset-init',
      component: PasswordResetInitView,
    },
    {
      path: '/password-reset-submit',
      name: 'password-reset-submit',
      component: PasswordResetSubmitView,
    },
    {
      path: '/password-reset-confirm',
      name: 'password-reset-confirm',
      component: PasswordResetConfirmView,
    },
    // Onboarding
    {
      path: '/site/onboarding',
      name: 'site-onboarding-survey',
      component: SiteOnboardingView,
    },
    {
      path: '/site/onboarding/site-creation',
      name: 'site-onboarding-site-creation',
      component: SiteOnboardingView,
    },
    {
      path: '/site/onboarding/:slug/review',
      name: 'site-onboarding-site-review',
      component: SiteOnboardingView,
    },
    {
      path: '/site/onboarding/:slug/active-directory',
      name: 'site-onboarding-active-directory',
      component: SiteOnboardingView,
    },
    {
      path: '/site/onboarding/:slug/network-traffic-tap',
      name: 'site-onboarding-network-traffic-tap',
      component: SiteOnboardingView,
    },
    {
      path: '/site/onboarding/:slug/incident-response',
      name: 'site-onboarding-incident-response',
      component: SiteOnboardingView,
    },
    {
      path: '/site/onboarding/:slug/next-gen-and-xdr-response',
      name: 'site-onboarding-next-gen-and-xdr-response',
      component: SiteOnboardingView,
    },
    {
      path: '/site/onboarding/:slug/enhancements',
      name: 'site-onboarding-enhancements',
      component: SiteOnboardingView,
    },
    // Customer
    {
      path: '/site/:customerSlug',
      name: 'customer-dashboard',
      component: CustomerTentantView
    },
    {
      path: '/site/:customerSlug/settings',
      name: 'customer-settings',
      component: CustomerSettings
    },
    {
      path: '/site/:customerSlug/notification',
      name: 'customer-notification',
      component: CustomerNotification
    },
    {
      path: '/site/:customerSlug/operator-access',
      name: 'customer-operator-access',
      component: CustomerOperatorView,
    },
    // Site Creation
    {
      path: '/site/:customerSlug/site-creation',
      name: 'customer-site-creation',
      component: CustomerSiteCreationFlowView,
    },
    {
      path: '/site/:customerSlug/site-creation/:siteSlug',
      name: 'customer-site-creation-with-site-slug',
      component: CustomerSiteCreationFlowView,
    },
    {
      path: '/site/:customerSlug/intake-config/:siteSlug',
      name: 'customer-site-intake-config',
      component: CustomerSiteCreationFlowView,
    },
    {
      path: '/site/:customerSlug/:siteSlug/alert',
      name: 'site-alert',
      component: SiteAlert
    },
    {
      path: '/site/:customerSlug/:siteSlug/notice',
      name: 'site-notice',
      component: SiteNotice
    },
    {
      path: '/site/:customerSlug/:siteSlug/personam',
      name: 'site-personam',
      component: SitePersonam
    },
    {
      path: '/site/:customerSlug/:siteSlug/collector',
      name: 'site-collector',
      component: SiteCollector
    },
  ]
})
export default router;