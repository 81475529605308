import { defineStore } from 'pinia'
import { useConfigStore } from './config';
import {AxiosClient} from './axios-client';
import { useSiteStore, type Site } from './site';

export interface Profile {
  name: string,
  industry: string,
  surveyComplete: boolean,
}
export enum SurveySelfInterest {
  MyCompany = 'MyCompany',
  OurClient = 'OurClient',
}
export interface Survey {
  selfInterest: SurveySelfInterest,
  companySize: string,
  incidentManagement: string,
  networkUsers: string,
  networkDevices: string,
}
export interface InitialSite {
  guid: string,
  created: Date,
  updated: Date,
  name: string,
  slug: string,
  nameAbbreviation: string,
}
export enum SyslogIntegration {
  Pending = 'Pending',
  Active = 'Active',
}
export enum DNSAccess {
  NoResponse = 'NoResponse',
  Allowed = 'Allowed',
  NotAllowed = 'NotAllowed',
}
export enum MirrorPortCapable {
  NoResponse = 'NoResponse',
  Capable = 'Capable',
  NotCapable = 'NotCapable',
  NotSureCapable = 'NotSureCapable',
}
export interface SiteProfile {
  site: Site,
  syslogIntegration: SyslogIntegration,
  dnsAccess: DNSAccess,
  mirrorPortCapable: MirrorPortCapable,
}
export const useProfileStore = defineStore('profile', {
  state: () => ({
    _profile: null as Profile | null,
    _survey: null as Survey | null,
    initialSite: null as InitialSite | null,
    siteProfile: null as SiteProfile | null,
  }),
  getters: {
    profile: (state): Profile => {
      return state._profile || {
        name: 'Placeholder Name',
        industry: 'Placeholder Industry',
        surveyComplete: false,
      };
    },
    survey: (state): Survey => {
      return state._survey || {
        selfInterest: SurveySelfInterest.MyCompany,
        companySize: '',
        incidentManagement: '',
        networkUsers: '',
        networkDevices: '',
      };
    },
    industries: (state): string[] => {
      return [
        'Managed Service Provider',
        'Agriculture',
        'Arts',
        'Construction',
        'Consumer Goods',
        'Coporate Services',
        'Design',
        'Education',
        'Energy & Metals',
        'Entertainment',
        'Finance',
        'Hardware & Networking',
        'Health Care',
        'Insurance',
        'Legal',
        'Manufacturing',
        'Media & Communications',
        'Nonprofit',
        'Public Administration',
        'Public Safety',
        'Recreation & Travel',
        'Real Estate',
        'Retail',
        'Software & IT Services',
        'Transportation & Logistics',
        'Wellness & Fitness',
      ];
    },
  },
  actions: {
    async completeSurvey(survey: Survey): Promise<void> {
      const configStore = useConfigStore();
      const url = `${configStore.apiUrl}/profile/survey`;
      try {
        const response = await AxiosClient.post(url, {
          self_interest: survey.selfInterest,
          company_size: survey.companySize,
          incident_management: survey.incidentManagement,
          network_users: survey.networkUsers,
          network_devices: survey.networkDevices,
        });
        if (response.status !== 202) {
          console.error('Error saving survey');
        }
      } catch (error: any) {
        console.error('Survey error:', error);
      }
      await this.updateProfile(this._profile!);
    },
    async updateProfile(profile: Profile): Promise<void> {
      this._profile = profile;
    },
    async loadSurvery(): Promise<void> {
      const configStore = useConfigStore();
      const url = `${configStore.apiUrl}/profile/survey`;
      try {
        const response = await AxiosClient.get(url);
        if (response.status === 200) {
          this._survey = {
            selfInterest: SurveySelfInterest[response.data.self_interest as keyof typeof SurveySelfInterest],
            companySize: response.data.company_size,
            incidentManagement: response.data.incident_management,
            networkUsers: response.data.network_users,
            networkDevices: response.data.network_devices,
          };
        } else if (response.status === 400) {
          // No issue
        } else {
          console.error('Error loading profile');
        }
      } catch (error: any) {
        console.error('Profile error:', error);
      }
    },
    async loadProfile(): Promise<void> {
      const configStore = useConfigStore();
      const url = `${configStore.apiUrl}/profile/detail`;
      try {
        const response = await AxiosClient.get(url);
        if (response.status === 200) {
          this._profile = {
            industry: response.data.industry,
            name: response.data.name,
            surveyComplete: response.data.survey_complete,
          };
        } else {
          console.error('Error loading profile');
        }
      } catch (error: any) {
        console.error('Profile error:', error);
      }
    },
    async loadInitialSite(): Promise<void> {
      const configStore = useConfigStore();
      const url = `${configStore.apiUrl}/profile/initial-site`;
      try {
        const response = await AxiosClient.get(url);
        if (response.status === 200) {
          this.initialSite = {
            guid: response.data.guid,
            created: new Date(response.data.created),
            updated: new Date(response.data.updated),
            name: response.data.name,
            slug: response.data.slug,
            nameAbbreviation: response.data.name_abbreviation,
          };
        } else if (response.status == 204) {
          // No issue
        } else {
          console.error('Error loading initial site', response.status);
        }
      } catch (error: any) {
        console.error('Initial site error:', error);
      }
    },
    async loadLocalSiteProfile(): Promise<void> {
      const siteStore = useSiteStore();
      const configStore = useConfigStore();
      if (siteStore.site.guid === '') {
        return;
      }
      const url = `${configStore.apiUrl}/profile/site/${siteStore.site.guid}`;
      try {
        const response = await AxiosClient.get(url);
        if (response.status === 200) {
          this.siteProfile = {
            site: {
              guid: response.data.site.guid,
              created: new Date(response.data.site.created),
              updated: new Date(response.data.site.updated),
              name: response.data.site.name,
              slug: response.data.site.slug,
              alertsOpen: response.data.site.alerts_open,
              alertsAck: response.data.site.alerts_ack,
              alertsClosed: response.data.site.alerts_closed,
              profilesTotal: response.data.site.profiles_total,
              sensorsOnline: response.data.site.sensors_online,
              sensorsTotal: response.data.site.sensors_total,
            },
            syslogIntegration: SyslogIntegration[response.data.syslog_integration as keyof typeof SyslogIntegration],
            dnsAccess: DNSAccess[response.data.dns_access as keyof typeof DNSAccess],
            mirrorPortCapable: MirrorPortCapable[response.data.mirror_port_capable as keyof typeof MirrorPortCapable],
          };
        } else if (response.status === 204) {
          this.siteProfile = {
            site: siteStore.site,
            syslogIntegration: SyslogIntegration.Pending,
            dnsAccess: DNSAccess.NoResponse,
            mirrorPortCapable: MirrorPortCapable.NoResponse,
          };
        } else {
          console.error('Error loading profile');
        }
      } catch (error: any) {
        // console.error('Profile error:', error.status, error);
      }
    },
    async saveDNSSettings(site: Site, dnsAccess: DNSAccess): Promise<void> {
      const oldValue = this.siteProfile?.dnsAccess;
      this.siteProfile!.dnsAccess = dnsAccess;
      const siteStore = useSiteStore();
      const configStore = useConfigStore();
      const url = `${configStore.apiUrl}/profile/site/${siteStore.site.guid}`;
      try {
        const response = await AxiosClient.post(url, {
          dns_access: dnsAccess,
          mirror_port_capable: this.siteProfile!.mirrorPortCapable,
        });
        if (response.status != 202) {
          this.siteProfile!.dnsAccess = oldValue!;
          console.log('Error saving DNS settings', response.status);
        }
      } catch (error: any) {
        this.siteProfile!.dnsAccess = oldValue!;
        console.error('Profile error:', error);
      }
    },
    async saveMirrorPortSettings(site: Site, mirrorPortCapable: MirrorPortCapable): Promise<void> {
      const oldValue = this.siteProfile?.mirrorPortCapable;
      this.siteProfile!.mirrorPortCapable = mirrorPortCapable;
      const siteStore = useSiteStore();
      const configStore = useConfigStore();
      const url = `${configStore.apiUrl}/profile/site/${siteStore.site.guid}`;
      try {
        const response = await AxiosClient.post(url, {
          dns_access: this.siteProfile!.dnsAccess,
          mirror_port_capable: mirrorPortCapable,
        });
        if (response.status != 202) {
          this.siteProfile!.mirrorPortCapable = oldValue!;
          console.log('Error saving Mirror Port settings', response.status);
        }
      } catch (error: any) {
        this.siteProfile!.mirrorPortCapable = oldValue!;
        console.error('Profile error:', error);
      }
    }
  },
});
