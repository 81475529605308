<template>
  <div class="card">
    <div style="padding-top: .75rem;" class="card-content">
      <div class="media">
        <div class="media-left">
          <figure class="image is-48x48">
            <img src="/public/personam-alert.svg" alt="Placeholder image">
          </figure>
        </div>
        <div class="media-content">
          <p class="title is-4" style="padding-top:10px;"> Forensic Enhancements </p>
          <p>
            Personam’s threat detection operates completely on sessionized data to evaluate your Site’s personams for abnormal behaviors and does not require anything further from you to be effective.  However, in the event an alert is raised, there are some additional data points our reporting engine can intake to make your analysts’ lives easier.  Specifically, DNS resolution and LDAP or Active Directory event log data to resolve user names.
          </p>
        </div>
      </div>
      <hr/>
      <div class="content">
        <div class="columns">
          <div class="column is-8">
            <div class="integration-box clickable">
              <div class="box">
                <div class="media">
                  <div class="media-left" style="width: 40px;">
                    <span style="font-size:40px; color: #83984B;" class="fa-solid fa-server"></span>
                  </div>
                  <div class="media-content media-title-content">
                    <p class="is-5">
                      IP Resolution, DNS Lookups, and DHCP Queries
                    </p>
                  </div>
                </div>
                <div class="content" style="margin-top: 20px;">
                  <p>
                    Allow Personam to resolve IP addresses to hostnames to help reduce the time needed for an analyst to determine impacted hosts.
                  </p>
                </div>
                <div class="columns">
                  <div class="column is-offset-7 is-5">
                    <div class="control">
                      <label class="radio button is-primary">
                        <input checked type="radio" name="rsvp" />
                        Enable
                      </label>
                      <label class="radio button is-primary disable-radio">
                        <!-- <input type="radio" name="rsvp" /> -->
                        Disable
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="integration-box">
              <div class="box">
                <div class="media">
                  <div class="media-left" style="width:40px; color: #83984B;">
                    <span style="font-size:40px;" class="fa-solid fa-envelope"></span>
                  </div>
                  <div class="media-content media-title-content">
                    <p class="is-5">
                      SysLog Message Forwarding to Personam.ai
                    </p>
                  </div>
                </div>
                <div class="content" style="margin-top: 20px;">
                  <p>
                    Personam by default operates on device-based behavior analysis.  If you would like Personam.ai to additionally report on user-based behaviors, tying Active Directory or LDAP authentication events can help your analysts identify which users are associated with which alert-raising activity.
                  </p>
                </div>
                <div class="buttons is-right">
                  <button style="margin-right: 1.35rem;" type="button" class="button is-primary"> Add Syslog Events </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.media-title-content {
  padding-top: 5px;
  font-size: 20px;
}
.disable-radio {
  -webkit-filter: grayscale(100%) !important;
  filter: none;
  cursor: inherit !important;
  &:hover {
    background-color: #4b9886 !important;
  }
}
</style>