<template>
  <div class="integration-box">
    <div class="box">
      <div class="media">
        <div class="media-left">
          <span style="font-size:40px;" class="fa-solid fa-network-wired"></span>
        </div>
        <div class="media-content">
          <p class="is-5">
            Webhooks
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.box {
  padding-top: .75rem;
  padding-bottom: 0;
}
.media-left {
  width: 50px;
  padding-top: .65rem;
}
.media-content {
  font-size: 20px;
  padding-top: 14px;
  padding-left: 10px;
}
</style>