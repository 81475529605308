import { defineStore } from "pinia";
import { useConfigStore } from "./config";
import { AxiosClient } from "./axios-client";
import { useTenancyStore, type Customer, type Site } from "./tenancy";

export const useSiteCreationStore = defineStore('siteCreation', {
  state: () => ({
    _site: null as Site | null,
  }),
  getters: {
    siteName: (state):string|null => {
      return state._site?.name || null
    },
    siteSlug: (state):string|null => {
      return state._site?.slug || null
    }
  },
  actions: {
    async clearSite(): Promise<void> {
      this._site = null;
    },
    async setSite(site: Site): Promise<void> {
      this._site = site;
    },
    async createSite(siteName: string): Promise<boolean> {
      const tenancyStore = useTenancyStore();
      for (var idx = 0; idx < tenancyStore.current_customer!.sites.length; idx++) {
        const site = tenancyStore.current_customer!.sites[idx];
        if (site.name.toLowerCase() == siteName.toLowerCase()) {
          return false;
        }
      }
      const configStore = useConfigStore();
      const url = `${configStore.apiUrl}/cs/site-creation`
      try {
        const resp = await AxiosClient.post(url, {
          name: siteName,
          customer_id: tenancyStore.current_customer!.guid
        });
        if (resp.status !== 200) {
          console.log("Error creating site");
        }
      } catch (error: any) {
        console.error("Error creating site", error);
      }
      await tenancyStore.loadTenants(true);
      for(let idx = 0; idx < tenancyStore.current_customer!.sites.length; idx++) {
        let site = tenancyStore.current_customer!.sites[idx];
        if (site.name == siteName) {
          this._site = site;
          tenancyStore.setCurrentSiteWithSlug(tenancyStore.current_customer!.slug, site.slug);
          break;
        }
      }
      return true;
    }
  }
})