<template>
  <div class="integration-box">
    <div class="box">
      <div class="media">
        <div class="media-left">
          <figure class="image">
            <svg xmlns="http://www.w3.org/2000/svg" x="20" y="0" width="76" height="40" viewBox="0 0 500 265" xml:space="preserve">
              <path fill="#0c77bd" d="M342.3 45.2s17-10 44.3-19.8c14.6-5.2 29.2-7.8 39.6-9.1-5.8-1.7-11.3-3.2-15.4-4.3-13-3.6-31.8-8-47.6-10.4-.7 3.8-2.6 9.9-7.1 18.7-6.2 12-13.8 24.9-13.8 24.9z"></path>
              <path fill="#14130f" d="M0 117h22.5v48.3L63.1 117h27.7l-46.2 53 48.1 59.3H63.3l-40.4-52.1h-.3v52.1H0V117z"></path>
              <path fill="#14130f" d="M101.4 150.8c8.4-3.9 19.6-6.3 28.8-6.3 25.3 0 35.7 10.5 35.7 34.9V190c0 8.4.2 14.6.3 20.7.2 6.3.5 12.1 1 18.5h-19c-.8-4.3-.8-9.8-1-12.4h-.3c-5 9.2-15.8 14.3-25.6 14.3-14.6 0-29-8.8-29-24.6 0-12.4 6-19.6 14.2-23.6s18.8-4.8 27.8-4.8h11.9c0-13.4-6-17.9-18.7-17.9-9.2 0-18.3 3.5-25.6 9l-.5-18.4zm25.3 64.7c6.6 0 11.7-2.9 15.1-7.4 3.5-4.7 4.5-10.6 4.5-17.1H137c-9.7 0-24 1.6-24 14.3 0 7.1 5.9 10.2 13.7 10.2zM230 163.8c-6.6-2.2-11.4-3.5-19.3-3.5-5.8 0-12.7 2.1-12.7 9.2 0 13.2 37.5 4.8 37.5 34.7 0 19.3-17.2 27-34.8 27-8.2 0-16.6-1.5-24.5-3.5l1.3-17.7c6.8 3.4 13.8 5.5 21.3 5.5 5.5 0 14.2-2.1 14.2-10.1 0-16.2-37.5-5.2-37.5-35.1 0-17.9 15.6-25.7 32.5-25.7 10.1 0 16.7 1.6 23.5 3.1l-1.5 16.1zm83.4 60.8c-7.9 4.3-16.9 6.6-28.5 6.6-27.4 0-43.3-15.8-43.3-42.9 0-24 12.7-43.8 38.5-43.8 30.7 0 39.4 21.1 39.4 50h-57.3c1 13.3 10.3 20.9 23.7 20.9 10.5 0 19.5-3.9 27.5-8.4v17.6zm-14.5-44.8c-.6-10.5-5.5-19.5-17.5-19.5-12.1 0-18.2 8.4-19.2 19.5h36.7zm63.7 27.1h.3l19.9-60.5h21.9l-31.1 82.2c-6.9 18.3-11.9 36.3-34.4 36.3-5.1 0-10.3-.8-15.3-2.2l1.4-15.9c2.7 1 5.8 1.4 11.1 1.4 8.7 0 14-5.9 14-14.8l-31.7-87h23.8l20.1 60.5zm47.7-56.1c8.4-3.9 19.6-6.3 28.8-6.3 25.3 0 35.7 10.5 35.7 34.9V190c0 8.4.2 14.6.3 20.7.2 6.3.5 12.1 1 18.5h-19c-.8-4.3-.8-9.8-1-12.4h-.3c-5 9.2-15.8 14.3-25.6 14.3-14.7 0-29-8.8-29-24.6 0-12.4 6-19.6 14.2-23.6s18.8-4.8 27.8-4.8h11.9c0-13.4-6-17.9-18.7-17.9-9.2 0-18.4 3.5-25.6 9l-.5-18.4zm25.2 64.7c6.6 0 11.7-2.9 15.1-7.4 3.5-4.7 4.5-10.6 4.5-17.1h-9.3c-9.7 0-24 1.6-24 14.3 0 7.1 6 10.2 13.7 10.2z"></path>
              <path fill="#0c77bd" d="M334.7 59.2c-3.1.9-13.4 27.5-18.7 42.2 9.7 6.7 19.7 12.7 29 16.7 18.7 8.2 24.5 4.8 36.1-.4 8.3-3.7 21.3-12.5 32.7-20.8-13.9-9.1-31.3-20.1-41.3-24.8-18.7-8.9-33.3-14.3-37.8-12.9zm16-59c-14.3-1-16.5 2.3-19.3 4.2-7.3 4-37.8 22.2-52.5 35-14 12.2-11.6 19.8-5.1 27.4 5.3 6.2 14.5 13.5 28.9 24.8.7.5 1.3 1 2 1.5 5.8-12.8 18.3-40.6 24.5-53.6 7.2-15.2 17.1-32 21.5-39.3zm125 32c-5-2.6-9.4-4-13.3-5.3-2-.6-7.1-2.1-13.5-4-11.4 1.5-30.3 4.3-48.6 8.7-19 4.5-50.8 20-50.8 20s40.3 13 78 34.9c1.3-1 2.4-1.9 3.5-2.8 11.7-9.6 41.4-34.3 47.8-45 1.4-2.7.1-4.9-3.1-6.5z"></path>
              <path d="M476.2 148.1c0-6.5 5.3-11.9 11.9-11.9s11.9 5.3 11.9 11.9c0 6.6-5.3 11.9-11.9 11.9s-11.9-5.3-11.9-11.9zm20.9 0c0-5.6-4-9.6-9-9.6s-9 4-9 9.6 4 9.6 9 9.6 9-4 9-9.6zm-13.6-6.8h5c3.2 0 5 1.1 5 3.9 0 2.3-1.3 3.4-3.5 3.6l3.6 5.9H491l-3.5-5.8H486v5.8h-2.5v-13.4zm2.5 5.8h2.2c1.5 0 2.8-.2 2.8-2 0-1.6-1.5-1.8-2.7-1.8H486v3.8z" fill="#1d1e1c"></path>
            </svg>
          </figure>
        </div>
        <div class="media-content">
          Tickets
        </div>
      </div>
      <div class="content">
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.box {
  padding-top: .75rem;
  padding-bottom: 0;
}
.media-left {
  width: 70px;
  padding-top: .65rem;
}
.media-content {
  font-size: 20px;
  padding-top: 22px;
  padding-left: 14px;
}
</style>