<template>
  <div class="integration-box">
    <div class="box" style="padding-top: .75rem; padding-bottom: 0;">
      <div class="media">
        <div class="media-left" style="width:120px; padding-top:.75rem;">
          <figure class="image">
            <img width="120" style="max-width:120px;" src="/public/splunk-logo.png" alt="Splunk Logo" />
          </figure>
        </div>
        <div class="media-content">
          <p class="is-5">
            SIEM
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.media-content {
  font-size: 20px;
  padding-top: 14px;
}
</style>