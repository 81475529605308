<template>
  <div class="integration-box">
    <div class="box">
      <div class="media">
        <div class="media-left">
          <figure class="image">
            <img src="/public/fortinet-logo.svg" alt="Placeholder image">
          </figure>
        </div>
        <div class="media-content">
          Next Gen<br/>
          Firewall
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.box {
  padding-top: .75rem;
  padding-bottom: 0;
}
.media-left {
  width: 120px;
  padding-top: 1.25rem;
}
.media-content {
  font-size: 20px;
  padding-top: 2px;
  padding-left: 0px;
}
</style>