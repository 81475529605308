import { defineStore } from 'pinia'
import { useConfigStore } from './config';
import { useMessageStore, MessageStatus, Message } from './message';
import { AxiosClient, type GeneralErrorMessage } from './axios-client';
import router from '../router';

export interface Site {
  guid: string;
  created: Date;
  updated: Date;
  name: string;
  slug: string;
  alertsOpen: number;
  alertsAck: number;
  alertsClosed: number;
  profilesTotal: string;
  sensorsOnline: number;
  sensorsTotal: number;
}
export interface SiteToken {
  detector_id: string;
  website: string;
  token: string;
}
export const useSiteStore = defineStore('site', {
  state: () => ({
    _site: null as null | Site,
    _sites: null as null | Site[],
  }),
  getters: {
    sites: (state) => state._sites || [],
    site: (state): Site => state._site || {
      guid: '',
      created: new Date(),
      updated: new Date(),
      name: 'Placeholder Site Name',
      slug: 'placeholder-site-slug',
      alertsOpen: 0,
      alertsAck: 0,
      alertsClosed: 0,
      profilesTotal: '0',
      sensorsOnline: 0,
      sensorsTotal: 0,
    },
  },
  actions: {
    async setSite(site: Site): Promise<void> {
      this._site = site;
    },
    async loadSiteToken(site_id: string): Promise<SiteToken|null> {
      const configStore = useConfigStore();
      const url = `${configStore.apiUrl}/adg/investigate/${site_id}/token`;
      try {
        const response = await AxiosClient.get(url);
        return {
          detector_id: response.data.detector_id,
          website: response.data.detector_website,
          token: response.data.detector_token
        } as SiteToken;
      } catch (error: any) {
        const castedError: GeneralErrorMessage = error;
        console.error(castedError);
        return null;
      }
    },
    async loadSites(forceReload: boolean = false): Promise<void> {
      const configStore = useConfigStore();
      const messageStore = useMessageStore();
      if (this._sites && !forceReload) {
        while (this._sites === null || this._sites.length == 0) {
          await new Promise(resolve => setTimeout(resolve, 100));
        }
        return;
      }
      this._sites = [];
      const url = `${configStore.apiUrl}/site/`;
      try {
        const response = await AxiosClient.get(url);
        for(let idx = 0; idx < response.data.length; idx++) {
          let site = response.data[idx];
          this.sites.push({
            guid: site.guid,
            created: new Date(site.created),
            updated: new Date(site.updated),
            name: site.name,
            slug: site.slug,
            alertsOpen: site.alerts_open,
            alertsAck: site.alerts_ack,
            alertsClosed: site.alerts_closed,
            profilesTotal: new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(site.profiles_total),
            sensorsOnline: 1,
            sensorsTotal: 2,
          });
        }
      } catch (error: any) {
        if (error.status == 401) {
          messageStore.pushMessage(new Message(MessageStatus.Danger, "Please Login first"));
        } else {
          // messageStore.pushMessage(new Message(MessageStatus.Danger, "Unable to load Sites"));
        }
      }
    },
    async loadLocalSite(): Promise<void> {
      if (this._sites === null) {
        await this.loadSites();
      }
      let localSite: Site = this._sites!.find((s: Site) => s.slug === router.currentRoute.value.params.slug)!;
      if (localSite == undefined) {
        await this.loadSites(true);
      }
      localSite = this._sites!.find((s: Site) => s.slug === router.currentRoute.value.params.slug)!;
      await this.setSite(localSite);
    },
  },
});