<template>
  <div class="integration-box">
    <div class="box">
      <div class="media">
        <div class="media-left">
          <figure class="image">
            <img src="/public/skyhigh-security-service.svg" alt="CheckPoint Logo">
          </figure>
        </div>
        <div class="media-content">
          Skyhigh Security<br/>
          Service Edge
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.box {
  padding-top: .55rem;
  padding-bottom: 0;
}
.media-left {
  width: 60px;
  padding-top: .25rem;
}
.media-content {
  font-size: 20px;
  padding-top: 4px;
}
</style>