<template>
  <div class="integration-box">
    <div class="box">
      <div class="media">
        <div class="media-left">
          <figure class="image">
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40px" height="40px" viewBox="0 0 128 128" version="1.1">
<g id="surface1">
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(4.705882%,2.352941%,15.686275%);fill-opacity:1;" d="M 70.398438 104.535156 L 28.445312 104.535156 C 25.699219 104.535156 23.464844 102.300781 23.464844 99.554688 L 23.464844 35.910156 L 104.535156 35.910156 L 104.535156 76.800781 L 110.222656 76.800781 L 110.222656 28.445312 C 110.222656 22.554688 105.445312 17.777344 99.554688 17.777344 L 28.445312 17.777344 C 22.554688 17.777344 17.777344 22.554688 17.777344 28.445312 L 17.777344 99.554688 C 17.777344 105.445312 22.554688 110.222656 28.445312 110.222656 L 70.398438 110.222656 Z M 23.464844 28.445312 C 23.464844 25.699219 25.699219 23.464844 28.445312 23.464844 L 99.554688 23.464844 C 102.300781 23.464844 104.535156 25.699219 104.535156 28.445312 L 104.535156 30.222656 L 23.464844 30.222656 Z M 23.464844 28.445312 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(4.705882%,2.352941%,15.686275%);fill-opacity:1;" d="M 68.570312 85.488281 C 68.46875 85.488281 68.367188 85.480469 68.265625 85.472656 C 67.136719 85.351562 66.1875 84.570312 65.851562 83.484375 L 57.964844 58.023438 L 51.960938 69.507812 C 51.472656 70.445312 50.5 71.035156 49.441406 71.035156 L 34.523438 71.035156 L 34.523438 65.347656 L 47.71875 65.347656 L 56.121094 49.273438 C 56.652344 48.25 57.746094 47.652344 58.898438 47.757812 C 60.046875 47.863281 61.015625 48.648438 61.359375 49.75 L 69.335938 75.5 L 74.246094 66.792969 C 74.75 65.898438 75.699219 65.347656 76.722656 65.347656 L 91.445312 65.347656 L 91.445312 71.035156 L 78.386719 71.035156 L 71.046875 84.039062 C 70.539062 84.941406 69.585938 85.488281 68.570312 85.488281 Z M 68.570312 85.488281 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(75.294118%,27.45098%,100%);fill-opacity:1;" d="M 110.933594 99.554688 L 117.332031 99.554688 L 117.332031 105.246094 L 110.933594 105.246094 Z M 110.933594 99.554688 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(75.294118%,27.45098%,100%);fill-opacity:1;" d="M 78.222656 99.554688 L 106.667969 99.554688 L 106.667969 105.246094 L 78.222656 105.246094 Z M 78.222656 99.554688 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(75.294118%,27.45098%,100%);fill-opacity:1;" d="M 110.933594 111.644531 L 117.332031 111.644531 L 117.332031 117.332031 L 110.933594 117.332031 Z M 110.933594 111.644531 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(75.294118%,27.45098%,100%);fill-opacity:1;" d="M 78.222656 111.644531 L 106.667969 111.644531 L 106.667969 117.332031 L 78.222656 117.332031 Z M 78.222656 111.644531 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(75.294118%,27.45098%,100%);fill-opacity:1;" d="M 110.933594 86.753906 L 117.332031 86.753906 L 117.332031 92.445312 L 110.933594 92.445312 Z M 110.933594 86.753906 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(75.294118%,27.45098%,100%);fill-opacity:1;" d="M 78.222656 86.753906 L 106.667969 86.753906 L 106.667969 92.445312 L 78.222656 92.445312 Z M 78.222656 86.753906 "/>
</g>
</svg>
          </figure>
        </div>
        <!-- https://documentation.n-able.com/remote-management/userguide/Content/EDR_Overview.htm -->
        <div class="media-content">
          <p class="is-5">
            N-central RMM
          </p>
        </div>
      </div>
      <div class="content">
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.box {
  padding-top: .25rem;
  padding-bottom: 0;
}
.media-left {
  width: 50px;
  padding-top: 1.35rem;
}
.media-content {
  font-size: 20px;
  padding-top: 24px;
  padding-left: 0px;
}
</style>