<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useConfigStore } from '@/stores/config';
import { useTenantStore } from '@/stores/tenant';
import { useTenancyStore } from '@/stores/tenancy';
import { useSiteCreationStore } from '@/stores/site-creation';
import type { Detector } from '@/stores/detector';
import router from '@/router';

const configStore = useConfigStore();
const tenantStore = useTenantStore();
const tenancyStore = useTenancyStore();
const siteCreationStore = useSiteCreationStore();

const detectors = ref<Detector[]>([]);
onMounted(async () => {
  const customerSlug = router.currentRoute.value.params.customerSlug!;
  const siteSlug = router.currentRoute.value.params.siteSlug!;
  // This component is loaded before a site could be created. Therefore protect from throwing an error
  if (siteSlug === undefined) {
    return
  }
  await tenancyStore.loadTenants();
  tenancyStore.setCurrentSiteWithSlug(customerSlug as string, siteSlug as string);
  siteCreationStore.setSite(tenancyStore.current_site!);
  const site = await tenantStore.loadTenantByGuid(tenancyStore.current_site!.guid);
  detectors.value = await tenantStore.loadDetectors(site);
})
const sensorDownloadUrl = computed(() => {
  if (detectors.value.length == 1) {
    const url = `${configStore.apiUrl}/ls/sensor-installer/${detectors.value[0].guid}`
    return url;
  }
  return '';
})
async function handleOperatorAccessClick(): Promise<void> {
  router.push({ name: 'customer-operator-access', params: {customerSlug: tenancyStore.current_customer!.slug}})
}
</script>
<template>
  <div class="card">
    <div style="padding-top: .75rem;" class="card-content">
      <div class="media">
        <div class="media-left">
          <figure class="image is-48x48">
            <img src="/public/personam-alert.svg" alt="Placeholder image">
          </figure>
        </div>
        <div class="media-content" style="padding-left: 0;">
          <p class="title is-4" style="padding-top: 8px;"> Site Network Intake </p>
          <p style="font-size:1rem;">
            Configure, Setup, or Call about our network intake capabilities.
          </p>
        </div>
      </div>
      <hr/>
      <div class="content">
        <div class="columns">
          <div class="column is-8">
            <div class="integration-box">
              <div class="box">
                <div class="media">
                  <div class="media-left" style="width:40px; color: #83984B;">
                    <span style="font-size:40px; color: #83984B;" class="fa-solid fa-computer"></span>
                  </div>
                  <div class="media-content media-title-content">
                    <p class="is-5">
                      Sensor Agent Download
                    </p>
                  </div>
                </div>
                <div class="content" style="margin-top: 20px;">
                  <p>
                    The Sensor Agent is a downloadable package which can be installed on a Windows VM or device in your network. Setup a SPAN/TAP interface on your existing infrastructure and point it to that VM/device. We'll automatically push the data to Personam.ai.
                  </p>
                </div>
                <div class="buttons is-right">
                  <a class="button is-primary" :href="sensorDownloadUrl" >
                    <span class="icon">
                      <i class="fa-solid fa-download"></i>
                    </span>
                    <span> Download Sensor Agent </span>
                  </a>
                </div>
              </div>
            </div>
            <div class="integration-box">
              <div class="box">
                <div class="media">
                  <div class="media-left" style="width: 40px;">
                    <span style="font-size:40px; color: #83984B;" class="fa-solid fa-faucet-drip"></span>
                  </div>
                  <div class="media-content media-title-content">
                    <p class="is-5">
                      Netflow Intake Inquery
                    </p>
                  </div>
                </div>
                <div class="content" style="margin-top: 20px;">
                  <p>
                    Personam.ai technology is capable of accepitng Netflow & sflow. We utilize VPN, TLS, and other methods of encryption to securly recieve data. Schedule a call with our team so we may align to your unique needs.
                  </p>
                </div>
                <div class="buttons is-right">
                  <a href="https://calendar.app.google/v87Arhof3cr4XWJx9" target="_blank" class="button is-primary"> Next Page </a>
                </div>
              </div>
            </div>
            <div class="integration-box">
              <div class="box">
                <div class="media">
                  <div class="media-left" style="width:40px; color: #83984B;">
                    <span style="font-size:40px; color: #83984B;" class="fa-solid fa-box-archive"></span>
                  </div>
                  <div class="media-content media-title-content">
                    <p class="is-5">
                      PCAP, Network Data Archive
                    </p>
                  </div>
                </div>
                <div class="content" style="margin-top: 20px;">
                  <p> Depending on network hardware and configuration there are many possible ways to build secure channels.  Schedule a call with our team so we may align to your unique needs.</p>
                </div>
                <div class="buttons is-right">
                  <a href="https://calendar.app.google/v87Arhof3cr4XWJx9" target="_blank" class="button is-primary"> Next Page </a>
                </div>
              </div>
            </div>
            <div class="buttons is-right" style="margin-right: 10px; margin-top: 35px;">
              <a @click="handleOperatorAccessClick()" target="_blank" class="button is-primary"> Add additional Operators </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.card-title {
  margin-top: 8px;
}
.media-content {
  font-size: 20px;
  padding-top: 2px;
  padding-left: 10px;
}
</style>