<script lang="ts" setup>
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useTenancyStore, type Site } from '@/stores/tenancy';
import { useSiteOperatorStore, type Operator} from '@/stores/site-operator';
import SiteOperatorAccessTable from './SiteOperatorAccessTable.vue';
const siteOperatorStore = useSiteOperatorStore();
const { currentOperator } = storeToRefs(siteOperatorStore)
const tenancyStore = useTenancyStore();
const { current_customer } = storeToRefs(tenancyStore);

enum ValidationStatus {
  Valid,
  Invalid,
}
interface ValidationState {
  status: ValidationStatus,
  message: string,
  content: string
}
watch(currentOperator, async (opt: Operator|null) => {
  if (opt != null) {
    validName.value.content = opt.name;
    validEmail.value.content = opt.email;
    if (opt.phone_number == null) {
      validPhoneNumber.value.content = '';
      SMSNotification.value = false;
    } else {
      validPhoneNumber.value.content = opt.phone_number;
      SMSNotification.value = true;
    }
  }
})
const validName = ref<ValidationState>({
  status: ValidationStatus.Valid,
  message: '',
  content: currentOperator.value?.name,
} as ValidationState);
async function validateName(event: KeyboardEvent): Promise<void> {
  const name = validName.value.content
  if (name == '' || name.length == 0) {
    validName.value.status = ValidationStatus.Invalid;
    validName.value.message = 'Name is required';
    return;
  };
  validName.value.status = ValidationStatus.Valid;
  validName.value.message = '';
  siteOperatorStore.updateCurrentOperator('name', validName.value.content)
}
const validEmail = ref<ValidationState>({
  status: ValidationStatus.Valid,
  message: '',
  content: currentOperator.value?.email,
} as ValidationState)
async function validateEmail(event: KeyboardEvent): Promise<void> {
  const email = validEmail.value.content;
  if (email == undefined || email.length == 0) {
    validEmail.value.status = ValidationStatus.Invalid;
    validEmail.value.message = 'Email is required';
    return;
  }
  const emailRegex = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');
  if (!emailRegex.test(email)) {
    validEmail.value.status = ValidationStatus.Invalid;
    validEmail.value.message = 'Invalid Email Format'
    return;
  }
  validEmail.value.status = ValidationStatus.Valid;
  validEmail.value.message = ''
  siteOperatorStore.updateCurrentOperator('email', validEmail.value.content)
}
const validPhoneNumber = ref<ValidationState>({
  status: ValidationStatus.Valid,
  message: '',
  content: currentOperator.value?.phone_number,
} as ValidationState)
async function validatePhoneNumber(event: KeyboardEvent|null): Promise<void> {
  if (SMSNotification.value == false) {
    validPhoneNumber.value.status = ValidationStatus.Valid;
    validPhoneNumber.value.message = ''
    return;
  }
  const phoneNumber = validPhoneNumber.value.content;
  if (phoneNumber == null || phoneNumber == '' || phoneNumber.length == 0) {
    validPhoneNumber.value.status = ValidationStatus.Invalid;
    validPhoneNumber.value.message = 'Phone number required'
    return;
  }
  const phoneRegexBasic = new RegExp('^[0-9]{10}$');
  const phoneRegexDash = new RegExp('^[0-9]{3}-[0-9]{3}-[0-9]{4}$');
  const phoneRegexDot = new RegExp('^[0-9]{3}\\.[0-9]{3}\\.[0-9]{4}$');
  const phoneRegexParam = new RegExp('^\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}$');
  if (
      phoneRegexBasic.test(phoneNumber) == false
      && phoneRegexDash.test(phoneNumber) == false
      && phoneRegexDot.test(phoneNumber) == false
      && phoneRegexParam.test(phoneNumber) == false) {
    validPhoneNumber.value.status = ValidationStatus.Invalid;
    validPhoneNumber.value.message = 'Invalid Phone Number';
    return;
  }
  validPhoneNumber.value.status = ValidationStatus.Valid;
  validPhoneNumber.value.message = '';
  siteOperatorStore.updateCurrentOperator('phoneNumber', validPhoneNumber.value.content);
}
const SMSNotification = ref<boolean>(currentOperator.value?.sms_notification || true);
watch(SMSNotification, async (newValue, oldValue) => {
  if (newValue == true) {
    await validatePhoneNumber(null)
  }
})
async function updateOperator(event: MouseEvent): Promise<void> {
  if (
    validName.value.status == ValidationStatus.Valid
    && validEmail.value.status == ValidationStatus.Valid
    && (
      validPhoneNumber.value.status == ValidationStatus.Valid
      || SMSNotification.value == false)) {
    await siteOperatorStore.stamp();
    siteOperatorStore.clearOperator();
    await siteOperatorStore.loadOperators(current_customer.value!);
  }
  
}
async function cancelForm(event: MouseEvent): Promise<void> {
  siteOperatorStore.clearOperator();
}
async function deleteOperator(event: MouseEvent): Promise<void> {
  console.log('delete operator', currentOperator.value!.name);
}
</script>
<template>
  <div class="field">
    <label class="label">Name</label>
    <div class="control">
      <input 
        class="input"
        :class="{
          'is-success': validName.status == ValidationStatus.Valid,
          'is-danger': validName.status == ValidationStatus.Invalid}"
        @keyup="validateName($event)"
        v-model="validName.content"
        type="text" placeholder="Operator Name">
    </div>
    <p
      v-if="validName.status == ValidationStatus.Invalid"
      class="help is-danger"> {{ validName.message  }} </p>
  </div>
  <div class="field">
    <label class="label">Email</label>
    <div class="control has-icons-left has-icons-right">
      <input
        class="input"
        :class="{
          'is-success': validEmail.status === ValidationStatus.Valid,
          'is-danger': validEmail.status === ValidationStatus.Invalid}"
        @keyup="validateEmail($event)"
        v-model="validEmail.content"
        type="email" placeholder="Operator Email">
      <span class="icon is-small is-left">
        <i class="fas fa-envelope"></i>
      </span>
      <span
        v-if="validEmail.status === ValidationStatus.Valid"
        class="icon is-small is-right">
        <i class="fas fa-exclamation-triangle"></i>
      </span>
    </div>
    <p 
      v-if="validEmail.status == ValidationStatus.Invalid"
      class="help is-danger"> {{ validEmail.message }}</p>
  </div>
  <SiteOperatorAccessTable />
  <div class="field">
    <p> Implement Site Selection </p>
  </div>
  <div v-if="SMSNotification == true" class="field">
    <label class="label"> Phone Number </label>
    <div class="control has-icons-left has-icons-right">
      <input
        class="input"
        :class="{
          'is-success': validPhoneNumber.status == ValidationStatus.Valid,
          'is-danger': validPhoneNumber.status == ValidationStatus.Invalid}"
        @keyup="validatePhoneNumber($event)"
        v-model="validPhoneNumber.content"
        type="tel" placeholder="Phone Number">
      <span class="icon is-small is-left">
        <i class="fas fa-phone"></i>
      </span>
      <span
        v-if="validPhoneNumber.status === ValidationStatus.Valid" class="icon is-small is-right">
        <i class="fas fa-exclamation-triangle"></i>
      </span>
    </div>
    <p
      v-if="validPhoneNumber.status === ValidationStatus.Valid"
      class="help is-danger"> {{ validPhoneNumber.message }} </p>
    <dl 
      v-if="validPhoneNumber.status == ValidationStatus.Invalid">
      <dt class="help is-danger"> US Valid Formats: </dt>
      <dd class="help is-danger"> -&nbsp;1234567890 </dd>
      <dd class="help is-danger"> -&nbsp;123-456-7890 </dd>
      <dd class="help is-danger"> -&nbsp;123.456.7890 </dd>
      <dd class="help is-danger"> -&nbsp;(123) 456-7890 </dd>
    </dl>
  </div>
  <div class="field">
    <div class="control">
      <label class="checkbox">
        <input type="checkbox" v-model="SMSNotification">
        Add to SMS Notification List
      </label>
    </div>
  </div>
  <div class="field is-grouped">
    <div class="control">
      <button @click="updateOperator($event)" class="button is-link">
        <span v-if="currentOperator?.guid == ''"> Create </span>
        <span v-else> Update </span>
      </button>
    </div>
    <div class="control">
      <button @click="cancelForm($event)" class="button is-link is-light"> Cancel </button>
    </div>
    <div class="control">
      <button @click="deleteOperator($event)" class="button is-link is-danger"> Delete </button>
    </div>
  </div>
</template>