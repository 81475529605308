import { defineStore } from 'pinia'
import {AxiosClient} from './axios-client';
import type { ErrorMessage } from "./message";
import type { Bureau } from './bureau';
import { useClientStore, type Client, ClientType } from './client';
import router from '@/router';

// http://localhost:5173/alert/9e09d365-fd7c-4ff9-8851-bf55dcbf8120
// https://devblogs.microsoft.com/typescript/announcing-typescript-3-8-beta/#type-only-imports-exports
export type Alert = {
  guid: string,
  created: Date,
  updated: Date,
  ts: Date,
  threat_score: number | string,
  count: number,
  latest: Date,
  client: Client
}
export enum AlertWorkflowState {
  Open = 'Open',
  Ack = 'Ack',
  Closed = 'Closed',
}
export type AlertWorkflow = {
  guid: string,
  state: AlertWorkflowState,
}
export type GridAlert = {
  guid: string,
  ts: Date,
  threatScore: number,
  client: Client,
  workflow: AlertWorkflow,
  lastAction: String,
  integrationTickenNo: string,
  detailsUrl: string,
  targetId: string,
}
function prepareAlerts(alerts: Alert[]) {
  for (const alert of alerts) {
    alert.created = new Date(alert.created)
    alert.updated = new Date(alert.updated)
    alert.ts = new Date(alert.ts)
    alert.threat_score = (alert.threat_score as Number).toFixed(1)
  }
}
function gridAlertToLocal(resp: any): GridAlert {
  const data =  {
    guid: resp.data.guid,
    ts: new Date(resp.data.ts),
    threatScore: resp.data.threat_score,
    client: {
      guid: resp.data.client.guid,
      name: resp.data.client.name,
      email: resp.data.client.email,
      clientType: ClientType[resp.data.client.client_type as keyof typeof ClientType],
    },
    workflow: {
      guid: resp.data.workflow.guid,
      state: AlertWorkflowState[resp.data.workflow.state as keyof typeof AlertWorkflowState],
    },
    lastAction: resp.data.last_action,
    integrationTickenNo: "",
    // detailsUrl: `/ClientWebUI/app/Itd/#/actor/${resp.data.client.name}`
    detailsUrl: '/ClientWebUI/Account/ExternalLogin',
    targetId: resp.data.target_id,
  } as GridAlert;
  return data;
}
export const useAlertStore = defineStore('alert', {
  state: () => ({
    _alert: null as Alert | null,
    _alerts: [] as Alert[],
    _gridAlert: null as GridAlert | null,
    _gridWorkflowState: null as AlertWorkflowState | null,
    _gridAlerts: [] as GridAlert[],
  }),
  getters: {
    alert: (state): Alert| null => { return state._alert; },
    alerts: (state): Alert[] => { 
      return state._alerts;
    },
    hasAlerts: (state): boolean => { return state._alerts.length > 0 },
    gridAlert: (state): GridAlert | null => { return state._gridAlert; },
    gridWorkflowState: (state): AlertWorkflowState | null => { 
      if (state._gridWorkflowState) {
        return state._gridWorkflowState;
      }
      if (state._gridAlert?.workflow) {
        return state._gridAlert.workflow.state;
      }
      return null;
    },
    gridAlerts: (state): GridAlert[] => { return state._gridAlerts; } 
  },
  actions: {
    async loadGridAlertByRouter(): Promise<GridAlert|null> {
      console.log('router-name', router.currentRoute.value.name)
      return null;
    },
    async markWorkflow(alertGuid: String, state: AlertWorkflowState): Promise<GridAlert|ErrorMessage> {
      let url = `/alert/update-workflow/${alertGuid}`;
      this._gridWorkflowState = state;
      const payload = {
        'state': state,
      };
      try {
        const resp = await AxiosClient.post(url, payload);
        const gridAlert = this._gridAlerts.find((gridAlert: GridAlert) => gridAlert.guid == alertGuid);
        gridAlert!.workflow.state = AlertWorkflowState.Ack;
        this._gridAlert = gridAlertToLocal(resp.data);
        this._gridWorkflowState = state;
        return this._gridAlert;
      } catch (error: any) {
        const castedError: ErrorMessage = error;
        return castedError;
      }
    },
    async nextWorkflow(alert: GridAlert): Promise<GridAlert|ErrorMessage> {
      let url = `/alert/update-workflow/${alert.guid}`;
      let nextState: AlertWorkflowState | null = null;
      if (alert.workflow.state === AlertWorkflowState.Open) {
        nextState = AlertWorkflowState.Closed;
      } else if (alert.workflow.state === AlertWorkflowState.Ack) {
        nextState = AlertWorkflowState.Closed;
      } else if (alert.workflow.state === AlertWorkflowState.Closed) {
        nextState = AlertWorkflowState.Open;
      }
      const payload = {
        'state': nextState,
      }
      try {
        const resp = await AxiosClient.post(url, payload);
        const gridAlert = this._gridAlerts.find((gridAlert: GridAlert) => gridAlert.guid === alert.guid);
        gridAlert!.workflow.state = nextState!;
        return gridAlertToLocal(resp.data);
      } catch(error: any) {
        const castedError: ErrorMessage = error;
        return castedError;
      }
    },
    async loadGridAlertsByBureau(bureau: Bureau): Promise<GridAlert[]|ErrorMessage> {
      let url = `/alert/grid-alerts-by-bureau/${bureau.guid}`;
      try {
        const resp = await AxiosClient.get(url);
        const gridAlerts = [];
        for (let idx = 0; idx<resp.data.length; idx++) {
          let gridAlert = resp.data[idx];
          gridAlerts.push(gridAlertToLocal({data: gridAlert}));
        }
        this._gridAlerts = gridAlerts;
      } catch (error: any) {
        const castedError: ErrorMessage = error;
        return castedError;
      }
      return this._gridAlerts;
    },
    async loadAlert(alertGuid: string): Promise<Alert> {
      let alert: Alert = this._alerts.find((alert: Alert) => alert.guid === alertGuid) as Alert;
      if (!alert) {
        const url = `/alert/by-guid/${alertGuid}`
        const resp = await AxiosClient.get(url);
        alert = {
          guid: resp.data.guid,
          created: new Date(resp.data.created),
          updated: new Date(resp.data.updated),
          ts: new Date(resp.data.ts),
          threat_score: resp.data.threat_score,
          count: resp.data.count,
          latest: new Date(resp.data.latest),
          client: {
            guid: resp.data.client.guid,
            name: resp.data.client.name,
            email: resp.data.client.email,
            clientType: ClientType[resp.data.client.client_type as keyof typeof ClientType],
          },
        };
        prepareAlerts([alert]);
        this._alerts.push(alert);
      }
      this._alert = alert;
      return alert;
    },
    async loadGridAlertThroughBureau(bureau: Bureau): Promise<Object|null> {
      if (router.currentRoute.value.name === 'Alert Details') {
        let alertGuid = router.currentRoute.value.params.guid;
        if (Array.isArray(alertGuid)) {
          alertGuid = alertGuid[0];
        }
        await this.loadGridAlertsByBureau(bureau);
        this._gridAlert = this._gridAlerts.find((gridAlert: GridAlert) => gridAlert.guid === alertGuid) as GridAlert;
      } else {
        console.error("Not Implemented");
      }
      return this._gridAlert;
    },
    async loadClientAlertsByAlert(alert: Alert): Promise<Alert[]> {
      const clientStore = useClientStore();
      const client = await clientStore.loadClientByAlert(alert);
      return this.loadAlertsByClient(client);
    },
    async loadAlertsByBureau(bureau: Bureau): Promise<Alert[]> {
      const url = `/alert/by-bureau/${bureau.slug}`;
      this._alerts = (await AxiosClient.get(url)).data
      prepareAlerts(this._alerts);
      return this._alerts.slice();
    },
    async loadAlertsByClient(client: Client): Promise<Alert[]> {
      const url = `/alert/by-client/${client.guid}`
      const resp = await AxiosClient.get(url);
      this._alerts = resp.data;
      prepareAlerts(this._alerts);
      return this._alerts;
    },
    async loadClientFromAlert(alertGuid: string): Promise<Client> {
      const alert = this._alerts.find((alert: Alert) => alert.guid === alertGuid);
      if (!alert) {
        const url = `/client/by-alert/${alertGuid}`
        const resp = await AxiosClient.get(url);
        return resp.data;
      }
      return alert!.client;
    }
  },
})