<script setup lang="ts">
import router from '@/router'
import { useProfileStore } from '@/stores/profile';
import { storeToRefs } from 'pinia';
import { useTenancyStore, type Customer } from '@/stores/tenancy'
const profileStore = useProfileStore();
const { initialSite } = storeToRefs(profileStore);

const tenancyStore = useTenancyStore();
async function handleToggle() {
  const customerSlug = router.currentRoute.value.params.slug;

  await tenancyStore.loadTenants()

  if (!customerSlug) {
    router.push({name: 'customer-dashboard', params: {customerSlug: tenancyStore.customers[0].slug}})
  } else {
    tenancyStore.setCurrentCustomerWithSlug(customerSlug as string);
    
    router.push({name: 'customer-notification', params: {
      customerSlug: tenancyStore.current_customer!.slug,
    }})
  }
}
</script>
<template>
  <div class="integration-box" @click="handleToggle()">
    <div class="box">
      <div class="media">
        <div class="media-left" style="width:40px;">
          <span style="font-size:40px; color: #83984B;" class="fa-solid fa-comment-sms"></span>
        </div>
        <div class="media-content">
          <p class="is-5">
            Texted Alerts
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.box {
  padding-top: .75rem;
  padding-bottom: 0;
}
.media-left {
  width: 50px;
  padding-top: .65rem;
}
.media-content {
  font-size: 20px;
  padding-top: 14px;
  padding-left: 10px;
}
</style>