<script setup lang="ts">
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useTenancyStore } from '@/stores/tenancy';
import { useSiteOperatorStore } from '@/stores/site-operator';
import router from '@/router';
import SideBar from '@/components/tenant/SideBar.vue';
import SiteOperatorAccessManage from '@/components/operator-access/SiteOperatorAccessManage.vue';
import SiteOperatorAccessPanel from '@/components/operator-access/SiteOperatorAccessPanel.vue';
const tenancyStore = useTenancyStore();
const { current_customer } = storeToRefs(tenancyStore);
const siteOperatorStore = useSiteOperatorStore();
const { currentOperator } = storeToRefs(siteOperatorStore);
onMounted(async () => {
  const customerSlug = router.currentRoute.value.params.customerSlug;
  await tenancyStore.loadTenants();
  tenancyStore.setCurrentCustomerWithSlug(customerSlug as string);
  await siteOperatorStore.clearOperator();
  await siteOperatorStore.loadOperators(current_customer.value!);
})
</script>
<template>
  <main class="section columns">
    <div class="column is-one-quarter">
      <SideBar :tenants="tenancyStore.customers" />
    </div>
    <div class="column is-three-quarter">
      <h1 class="title"> {{ current_customer?.name }} Operator Access </h1>
      <div class="columns">
        <div class="column is-5">
          <SiteOperatorAccessPanel />
        </div>
        <div class="column is-6" v-if="currentOperator != null">
          <SiteOperatorAccessManage />
        </div>
      </div>
    </div>
  </main>
</template>
<style scoped lang="scss">
.large-action-icon {
  font-size: 32px;
  line-height: 32px;
}
.force-text-center {
  text-align: center !important;
}
.embold-link {
  font-weight: 800;
  font-size: 1.1em !important;
}
</style>