<script lang="ts" setup>
import AlertGridRow from '@/components/tenant/alert/AlertGridRow.vue';
import { onMounted, ref, computed } from 'vue'
import { useAlertGridStore, Workflow_State, type AlertGrid } from '@/stores/alert-grid';
import { storeToRefs } from 'pinia';
import moment from 'moment';
import type { Detector } from '@/stores/detector';
import { useConfigStore } from '@/stores/config';
import { useTenantStore } from '@/stores/tenant';
import { useTenancyStore } from '@/stores/tenancy';
import router from '@/router';

const configStore = useConfigStore();
const detectors = ref<Detector[]>([]);
const tenantStore = useTenantStore();
const tenancyStore = useTenancyStore();
const alertGridStore = useAlertGridStore()
const { alertGrid } = storeToRefs(alertGridStore);
const { current_customer, current_site } = storeToRefs(tenancyStore);


onMounted(async () => {
  await tenancyStore.loadTenants();
  const customerSlug = router.currentRoute.value.params.customerSlug;
  const siteSlug = router.currentRoute.value.params.siteSlug;
  tenancyStore.setCurrentCustomerWithSlug(customerSlug as string);
  tenancyStore.setCurrentSiteWithSlug(customerSlug as string, siteSlug as string);
})
const statusFilter = ref<Workflow_State[]>([Workflow_State.OPEN]);
enum Sort {
  ASC = 'ASC',
  DESC = 'DESC',
  None = 'None'
}
const dateAndTimeSort = ref<Sort>(Sort.None);
async function  sortDateandTime(event: Event) {
  if (dateAndTimeSort.value === Sort.DESC) {
    dateAndTimeSort.value = Sort.ASC;
    alertGrid.value.sort((a:AlertGrid, b:AlertGrid) => {
      return moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf();
    });
  } else {
    dateAndTimeSort.value = Sort.DESC;
    alertGrid.value.sort((a:AlertGrid, b:AlertGrid) => {
      return moment(b.timestamp).valueOf() - moment(a.timestamp).valueOf();
    });
  }
}
const sortUsernameandDevice = ref<Sort>(Sort.None);
async function sortUsernameDevice(event: Event) {
  if (sortUsernameandDevice.value === Sort.DESC) {
    sortUsernameandDevice.value = Sort.ASC;
    alertGrid.value.sort((a:AlertGrid, b:AlertGrid) => {
      return a.target_type.localeCompare(b.target_type);
    });
  } else {
    sortUsernameandDevice.value = Sort.DESC;
    alertGrid.value.sort((a:AlertGrid, b:AlertGrid) => {
      return b.target_type.localeCompare(a.target_type);
    });
  }
}
const threatScoreSort = ref<Sort>(Sort.None);
async function sortThreatScore(event: Event) {
  if (threatScoreSort.value === Sort.DESC) {
    threatScoreSort.value = Sort.ASC;
    alertGrid.value.sort((a:AlertGrid, b:AlertGrid) => {
      return a.threat_score - b.threat_score;
    });
  } else {
    threatScoreSort.value = Sort.DESC;
    alertGrid.value.sort((a:AlertGrid, b:AlertGrid) => {
      return b.threat_score - a.threat_score;
    });
  }
}
</script>
<template>
  <div class="columns">
    <div class="column is-8">
      <h2 class="title"> Alerts </h2>
    </div>
    <div class="column is-4 is-right">
      <div class="buttons is-fullwidth is-right">
        <RouterLink class="button is-primary" :to="{
          name: 'customer-site-intake-config',
          params: {customerSlug: current_customer?.slug, siteSlug: current_site?.slug}
        }">
          <span class="icon">
            <i class="fa-solid fa-cog"></i>
          </span>
          <span> Configure Site Network Intake </span>
        </RouterLink>
      </div>
    </div>
  </div>
  
  <table class="table is-striped is-bordered is-fullwidth has-text-centered">
    <thead>
      <tr>
        <th
          @click="sortDateandTime($event)"
          class="has-text-centered"
          data-tooltip="Date & time Alert was first calculated, in UTC">
          Date & Time
          <span class="icon is-small">
            <i class="fas"
              :class="{'fa-caret-up': dateAndTimeSort === Sort.ASC, 'fa-caret-down': dateAndTimeSort === Sort.DESC}"></i>
          </span>
        </th>
        <th
          @click="sortUsernameDevice($event)"
          class="has-text-centered"
          data-tooltip="Alerts can be generated for a User or Device">
          User / Device
          <span class="icon is-small">
            <i class="fas"
              :class="{'fa-caret-up': sortUsernameandDevice === Sort.ASC, 'fa-caret-down': sortUsernameandDevice === Sort.DESC}"></i>
          </span>
        </th>
        <th
          @click="sortThreatScore($event)"
          class="has-text-centered"
          data-tooltip="Threat Score is the measure of statistically significant anomalies relative to a Cohort displayed as a percentage instead of 0 - 1.">
          Threat Score
          <span class="icon is-small">
            <i class="fas"
              :class="{'fa-caret-up': threatScoreSort === Sort.ASC, 'fa-caret-down': threatScoreSort === Sort.DESC}"></i>
          </span>
        </th>
        <th class="has-text-centered"> Forensic Toolkit </th>
      </tr>
    </thead>
    <tbody>
      <AlertGridRow
        v-for="(alert, index) in alertGrid" :key="index"
        :alert="alert"
        :status-filter="statusFilter" />
    </tbody>
    <tfoot></tfoot>
  </table>
</template>