<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useConfigStore } from '@/stores/config';

const configStore = useConfigStore();
const { webUrl } = storeToRefs(configStore);
</script>
<template>
  <section class="is-full-width authen-background">
    <div class="columns">
      <div class="column is-half">
        Left Side
      </div>
      <div class="column is-half">
        <section class="authen-window">
          <h1 class="title"> Personam.ai </h1>
          <h2 class="subtitle"> Password Reset Started </h2>
          <p> Please check your email for a link to finish reseting your password. </p>
        </section>
      </div>
    </div>
  </section>
</template>