import { defineStore } from "pinia";
import { AxiosClient, type GeneralErrorMessage } from "@/stores/axios-client";
import { useConfigStore } from "@/stores/config";
import { useTenancyStore } from "./tenancy";

export enum Target_Type {
  USER = "User",
  DEVICE = "Device"
}

export enum Workflow_State {
  OPEN = "Open",
  ACK = "Ack",
  CLOSE = "Close"
}

export interface AlertGrid {
  guid: string,
  created: Date,
  updated: Date,
  timestamp: Date,
  threat_score: number,
  target_id: string,
  target_type: Target_Type,
  workflow_state: Workflow_State
}
export const useAlertGridStore = defineStore('alertGrid', {
  state: () => ({
    _alert_grid: null as AlertGrid[]|null,
  }),
  getters: {
    alertGrid: (state): AlertGrid[] => { 
      return state._alert_grid || []
    }
  },
  actions: {
    async loadGridData(): Promise<AlertGrid[]|null> {
      const configStore = useConfigStore();
      const tenancyStore = useTenancyStore()
      const url = `${configStore.apiUrl}/adg/by-site/${tenancyStore.current_site?.guid}`;
      try {
        const response = await AxiosClient.get(url);
        if (response.status === 200) {
          this._alert_grid = response.data;
          return response.data as AlertGrid[]
        } else if (response.status == 204) {
          this._alert_grid = [];
          return [] as AlertGrid[];
        } else {
          console.error('Unable to load customers');
          this._alert_grid = null;
          return this._alert_grid
        }
      } catch (error: any) {
        console.error('Unable to load customers', error);
        this._alert_grid = null;
        return this._alert_grid
      }
    }
  }
})