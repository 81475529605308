<template>
  <div class="integration-box">
    <div class="box">
      <div class="media">
        <div class="media-left">
          <figure class="image">
            <image src="/public/splunk-logo.png" alt="Splunk Logo" />
          </figure>
        </div>
        <div class="media-content">
          Incident<br/>
          Response
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.box {
  padding-top: .75rem;
  padding-bottom: 0;
}
.media-left {
  width: 100px;
  padding-top: 1.15rem;
}
.media-content {
  font-size: 20px;
  padding-top: 0px;
  padding-left: 10px;
}
</style>