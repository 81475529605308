<script setup lang="ts">
import { type OperatorAccess, useAccessStore, type CountryInfo } from '@/stores/access';
const accessStore = useAccessStore();
const { countryInfo, } = storeToRefs(accessStore);
import { useSiteOperatorStore, type Operator } from '@/stores/site-operator';
const siteOperatorStore = useSiteOperatorStore();
import { useTenancyStore } from '@/stores/tenancy';
const tenancyStore = useTenancyStore();
import { useBureauStore } from '@/stores/bureau';
const bureauStore = useBureauStore();
import { useSMSStore } from '@/stores/sms';
const smsStore = useSMSStore();
import { storeToRefs } from 'pinia';
import { onMounted, ref, type PropType, onActivated } from 'vue';
import { Message, MessageStatus, useMessageStore } from '@/stores/message';
import router from '@/router';
const messageStore = useMessageStore();
const { messages } = storeToRefs(messageStore);

const props = defineProps({
  oa: {
    type: Object as PropType<Operator>,
    required: true
  },
  smsNote: {
    type: String,
    required: true
  }
});
const changeRequest = ref<boolean>(false);
const countryInfoSelect = ref<CountryInfo|null>(null);
const phoneNumber = ref<string|null>(null);
const validPhone = ref<{state: boolean, message: string}>({
  state: true,
  message: '',
});

onMounted(async () => {
  countryInfoSelect.value = countryInfo.value[0];
  if (props.oa.phone_number === null) {
    phoneNumber.value = props.oa.phone_number
  } else {
    phoneNumber.value = props.oa.phone_number.substring(countryInfoSelect.value!.value.length, props.oa.phone_number!.length);
  }
  await tenancyStore.loadTenants();
  const customerSlug = router.currentRoute.value.params.customerSlug!;
  tenancyStore.setCurrentCustomerWithSlug(customerSlug as string);
})
async function validatePhoneNumber(event: Event): Promise<void> {
  const phone = phoneNumber.value;
  if (phone === undefined || phone === null) {
    validPhone.value = {
      state: false,
      message: 'Phone Number is required far Add',
    };
    return;
  }
  if (phone.length === 0) {
    validPhone.value = {
      state: false,
      message: 'Phone Number is required for Add',
    };
    return;
  }
  const phoneRegexBasic = new RegExp('^[0-9]{10}$');
  const phoneRegexDash = new RegExp('^[0-9]{3}-[0-9]{3}-[0-9]{4}$');
  const phoneRegexDot = new RegExp('^[0-9]{3}\\.[0-9]{3}\\.[0-9]{4}$');
  const phoneRegexParam = new RegExp('^\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}$');
  if (
      phoneRegexBasic.test(phone) === false
      && phoneRegexDash.test(phone) === false
      && phoneRegexDot.test(phone) === false
      && phoneRegexParam.test(phone) === false) {
    validPhone.value = {
      state: false,
      message: 'Invalid Phone Number Format',
    };
    return;
  }
  validPhone.value = {
    state: true,
    message: '',
  };
}
async function commitNumber(operator: Operator): Promise<void> {
  if (phoneNumber.value === null) {
    return;
  }
  if (validPhone.value.state === false) {
    return;
  }
  siteOperatorStore.setCurrentOperator(operator);
  siteOperatorStore.updateCurrentOperator('phoneNumber', `${countryInfoSelect.value!.value}${phoneNumber.value}`)
  await siteOperatorStore.stamp();
  changeRequest.value = false;
}
async function sendSMSTest(oa: Operator): Promise<void> {
  const bureau = await bureauStore.loadBureauByGuid(oa.sites[0].guid);
  const message = await smsStore.sendTest({
    recipients: [oa.phone_number as string],
    body: props.smsNote
  }, bureau);
  if (Object.prototype.hasOwnProperty.call(message, "status")) {
    messages.value.unshift(message as Message);
  } else if (Object.prototype.hasOwnProperty.call(message, "message")) {
    messages.value.unshift(new Message(MessageStatus.Warning, message.message));
  } else {
    console.error(message)
    messages.value.unshift(new Message(MessageStatus.Danger, "System Error - Contact Customer Support"));
  }
}
</script>
<template>
  <td 
    class="has-text-centered">
    <div 
      class="field is-grouped is-grouped-centered"
      v-if="oa.phone_number && !changeRequest">
      <p class="control">
        <a 
          class="button is-info"
          @click="changeRequest = !changeRequest">
          Change
        </a>
      </p>
      <p class="control">
        <a
          class="button is-danger"
          @click="sendSMSTest(oa);">
          SMS Test
        </a>
      </p>
    </div>
    <div
      v-if="!oa.phone_number || changeRequest === true"
      class="field has-addons"
      style="margin-bottom: 0em;">
      <p class="control">
        <span class="select">
          <select 
            v-model="countryInfoSelect">
            <option
              v-for="cv in countryInfo"
              :value="cv" :key="cv.value">
              {{ cv.value }} {{ cv.code }}
            </option>
          </select>
        </span>
      </p>
      <div class="control">
        <input
          class="input"
          type="phone"
          v-model="phoneNumber"
          :class="{ 'is-danger': !validPhone.state, 'is-success': validPhone.state }"
          @keyup="validatePhoneNumber($event)"
          placeholder="SMS Number">
      </div>
      <div class="control">
        <a 
          class="button is-primary"
          @click="commitNumber(oa)">
          <span v-if="!changeRequest"> Add </span>
          <span v-if="changeRequest"> Change </span>
        </a>
      </div>
      <div class="control">
        <a 
          class="button is-info"
          v-if="oa.phone_number && changeRequest"
          @click="changeRequest = !changeRequest">
          <span> Cancel </span>
        </a>
      </div>
    </div>
    <p
      class="help is-danger"
      style="text-align: left; margin:0; padding: 0;"
      v-if="!validPhone.state">
      {{ validPhone.message }}
    </p>
    <dl 
      v-if="!validPhone.state && validPhone.message === 'Invalid Phone Number Format'"
      style="text-align: left; margin-bottom: 0.75em;">
      <dt class="help is-danger"> Valid Formats: </dt>
      <dd class="help is-danger"> -&nbsp;1234567890 </dd>
      <dd class="help is-danger"> -&nbsp;123-456-7890 </dd>
      <dd class="help is-danger"> -&nbsp;123.456.7890 </dd>
      <dd class="help is-danger"> -&nbsp;(123) 456-7890 </dd>
    </dl>
  </td>
</template>