import { defineStore } from "pinia";
import { shallowRef, type Component, type ShallowRef } from "vue";

export interface ModalComponent {
  component: ShallowRef<Component> | Component,
  routeName: string,
  title: string,
}

export const useSiteOnboardingModal = defineStore('siteOnboardingModal', {
  state: () => ({
    component: null as ModalComponent | null,
  }),
  getters: {
    active: (state: any) => state.component !== null,
    title: (state: any) => state.component?.title,
  },
  actions: {
    async toggle(mc: ModalComponent): Promise<void> {
      mc.component = shallowRef(mc.component);
      this.component = mc;
    },
    async toggleClose(): Promise<void> {
      this.component = null;
    }
  }
});
