<template>
  <div class="integration-box">
    <div class="box">
      <div class="media">
        <div class="media-left">
          <figure class="image">
            <img src="/public/sentinelone-logo.svg" alt="Sentinal One Logo">
          </figure>
        </div>
        <div class="media-content">
          Singularity
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.box {
  padding-top: .85rem;
  padding-bottom: 0;
}
.media-left {
  width: 140px;
  padding-top: 1.00rem;
}
.media-content {
  font-size: 20px;
  padding-top: 14px;
}
</style>