<template>
  <div class="integration-box">
    <div class="box">
      <div class="media">
        <div class="media-left">
          <figure class="image">
            <!-- https://learn.microsoft.com/en-us/azure/architecture/icons/ -->
            <svg id="a75e3f3a-2661-410b-82fb-d300d37dea2d" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 18 18"><defs><linearGradient id="aff60ddf-eec1-40bf-8bf5-f3e3b50e8818" x1="9" y1="16.21" x2="9" y2="0.62" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#1b93eb" /><stop offset="0.21" stop-color="#2095eb" /><stop offset="0.44" stop-color="#2e9ced" /><stop offset="0.69" stop-color="#45a7ef" /><stop offset="0.95" stop-color="#64b6f1" /><stop offset="1" stop-color="#6bb9f2" /></linearGradient></defs><title>Icon-security-248</title><path d="M16,8.44c0,4.57-5.53,8.25-6.73,9a.43.43,0,0,1-.46,0C7.57,16.69,2,13,2,8.44V2.94a.44.44,0,0,1,.43-.44C6.77,2.39,5.78.5,9,.5s2.23,1.89,6.53,2a.44.44,0,0,1,.43.44Z" fill="#1b93eb" /><path d="M15.38,8.48c0,4.2-5.07,7.57-6.17,8.25a.4.4,0,0,1-.42,0c-1.1-.68-6.17-4.05-6.17-8.25v-5A.41.41,0,0,1,3,3c3.94-.11,3-1.83,6-1.83S11.05,2.93,15,3a.41.41,0,0,1,.39.4Z" fill="url(#aff60ddf-eec1-40bf-8bf5-f3e3b50e8818)" /><path d="M9,6.53A2.88,2.88,0,0,1,11.84,9a.49.49,0,0,0,.49.4h1.4a.49.49,0,0,0,.5-.53,5.26,5.26,0,0,0-10.46,0,.49.49,0,0,0,.5.53h1.4A.49.49,0,0,0,6.16,9,2.88,2.88,0,0,1,9,6.53Z" fill="#c3f1ff" /><circle cx="9" cy="9.4" r="1.91" fill="#fff" /></svg>
          </figure>
        </div>
        <div class="media-content">
          Microsoft Sentinel
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.box {
  padding-top: .75rem;
  padding-bottom: 0;
}
.media-left {
  width: 50px;
  padding-top: .65rem;
}
.media-content {
  font-size: 20px;
  padding-top: 14px;
  padding-left: 0px;
}
</style>