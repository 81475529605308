<template>
  <div class="card events-card">
    <div class="card-content">
      <section class="section content" style="margin-bottom: 0; padding-top: 0;">
        <p class="subtitle is-4"> Request Cisco netflow Collection </p>
        <p>
          Personam.AI is capable of providing a netflow collection service. Please setup a meeting with our team 
          and we'll be able to work through the details in the technology layer with you.
        </p>
        <div class="buttons is-right">
          <a href="https://calendar.app.google/v87Arhof3cr4XWJx9" target="_blank" class="button is-primary"> Let us call you </a>
        </div>
      </section>
    </div>
    <footer class="card-footer">
    </footer>
  </div>
</template>