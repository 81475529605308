<template>
  <div class="integration-box">
    <div class="box" style="padding-top: .75rem; padding-bottom: 0;">
      <div class="media">
        <div class="media-left" style="width:50px; padding-top:.65rem;">
          <figure class="image">
            <img src="/public/microsoft-azure.svg" alt="Microsoft Azure">
          </figure>
        </div>
        <div class="media-content" style="padding-top:18px;">
          Microsoft Azure
        </div>
      </div>
      <div class="content">
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.media-content {
  font-size: 20px;
}
</style>