<template>
  <div class="integration-box">
    <div class="box">
      <div class="media">
        <div class="media-left">
          <figure class="image">
            <img src="/public/mcafee-logo.svg" alt="CheckPoint Logo">
          </figure>
        </div>
        <div class="media-content">
          McAfee<br/>
          Firewall Enterprise
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.box {
  padding-top: .55rem;
  padding-bottom: 0;
}
.media-left {
  width: 50px;
  padding-top: .45rem;
}
.media-content {
  font-size: 20px;
  padding-top: 4px;
}
</style>