<script setup lang="ts">
import { onUpdated, ref, watch } from 'vue';
import { useBureauStore, type Bureau } from '@/stores/bureau';
import { useAuthenStore } from '@/stores/authen';
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import router from '@/router';

const bureauStore = useBureauStore();
const bureau = ref<Bureau|null>(null)
const settingsUrl = ref<string>('/login');
const executiveSummaryUrl = ref<string>('/login');
const showMenu = ref<boolean>(true);
const authenStore = useAuthenStore();
const { isLoggedIn } = storeToRefs(authenStore);

async function manageUrls () {
  bureau.value = await bureauStore.loadBureauFromRouter();
  if (bureau.value === null || bureau.value.guid == '') {
    showMenu.value = false;
    return;
  }
  showMenu.value = true;
  settingsUrl.value = `/configure/${bureau.value!.guid}/operator`;
  executiveSummaryUrl.value = `/bureau/${bureau.value!.slug}`;
}
watch(router.currentRoute, manageUrls);
onMounted(manageUrls);
</script>
<template>
  <header>
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <img src="/public/personam-nav.png" width="325">
        </a>
        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div id="navbarBasicExample" class="navbar-menu" v-if="showMenu === true">
        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <!-- <a class="button is-primary">
                <strong> Contact Personam </strong>
              </a> -->
              <router-link to="/login" class="button is-light">
                <span v-if="isLoggedIn == true"> Logout </span>
                <span v-else> Login </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>