import axios, { AxiosError } from 'axios'
import type { AxiosResponse } from 'axios'
import { Message, ErrorMessage, MessageStatus } from './message'
import router from '@/router'
import { useAuthenStore } from './authen'

let api_base = 'https://api.personam.ai'
if (import.meta.env.VITE_APIURL !== undefined) {
  api_base = import.meta.env.VITE_APIURL;
} else if (window.location.hostname == 'localhost') {
    api_base = 'http://localhost:8200'
} else {
  console.log('API Base not set. Using default value:', api_base);
}
export interface GeneralErrorMessage {
  code: Number,
  message: string,
}
export function response_to_message(resp: AxiosResponse): Message {
  let message_status = null;
  if (resp.data.status == "Info") {
    message_status = MessageStatus.Info
  } else if (resp.data.status == "Warning") {
    message_status = MessageStatus.Warning
  } else if (resp.data.status == "Danger") {
    message_status = MessageStatus.Danger
  } else {
    throw new Error("Not Implemented");
  }
  return new Message(message_status, resp.data.message as String);
}
export const AxiosClient = axios.create({
  withCredentials: true,
  baseURL: api_base,
  headers: {
    'Content-Type': 'application/json',
  }
})
AxiosClient.defaults.withCredentials = true;
AxiosClient.interceptors.response.use(async (response: AxiosResponse) => {
  return response
}, (err: AxiosError) => {
  if (err.response?.status === 401) {
    const authenStore = useAuthenStore();
    authenStore.addFailedRoute(router.currentRoute.value).then(() => {
      router.push({name: 'login'});
    });
    return Promise.reject(err.response!);
  } else {
    if (Object.prototype.hasOwnProperty.call(err, 'response') === true) {
      // @ts-ignore
      const errorCode = err.response!.data.code || 'Unknown';
      // @ts-ignore
      const errorReason = err.response!.data.reason || null;
      // @ts-ignore
      const errorMessage = errorReason || err.response!.data.message || 'Unknown';
      throw new ErrorMessage(errorCode, errorMessage);
    } else {
      // console.error("Unhandled Error:", err);
      return err;
    }
  }
});
