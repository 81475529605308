import { defineStore } from "pinia";
import type { Bureau } from "./bureau";
import { AxiosClient, response_to_message } from './axios-client';
import type { ErrorMessage, Message } from "./message";

export type SMSMessage = {
  recipients: string[],
  body: string,
}
export const useSMSStore = defineStore('sms', {
  state: () => ({
  }),
  getters: {},
  actions: {
    async sendTest(message: SMSMessage, bureau: Bureau): Promise<ErrorMessage|Message> {
      const url = `/sms/send-test/${bureau.guid}`;
      try {
        const resp = await AxiosClient.post(url, message);
        return response_to_message(resp);
      } catch (error: any) {
        const castedError: ErrorMessage = error;
        return castedError;
      }
    },
  }
});