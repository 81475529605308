import type { Client } from "./client";
import { useAlertStore, type Alert } from "./alert";
import { defineStore } from "pinia";
import { AxiosClient } from "./axios-client";
import router from "@/router";

export type Bureau = {
  guid: string,
  name: string,
  slug: string,
};
export type BureauAgg = {
  bureau: Bureau,
  cohort_count: number,
  client_count: number,
  alert_count: number,
  sensorCount: number,
  detectorCount: number,
  open_count: number,
  ack_count: number,
};
const defaultBureau: Bureau = {
  guid: '',
  name: '',
  slug: '',
};
const stringtoInt = function(input: string): number {
  let result = 0;
  for(let idx = 0; idx < input.length; idx++) {
    result = result + input.charCodeAt(idx);
  }
  return result;
};
export const useBureauStore = defineStore('bureau', {
  state: () => ({
    _bureauAgg: [] as BureauAgg[],
    _bureau: defaultBureau,
    _loadedBureau: defaultBureau,
  }),
  getters: {
    bureauAgg: (state): BureauAgg[] => { 
      return state._bureauAgg;
    },
    currentBureau: (state): Bureau => {
      return state._bureau;
    },
    loadedBureau: (state): Bureau => {
      return state._loadedBureau;
    }
  },
  actions: {
    async loadBureauByGuid(guid: string): Promise<Bureau> {
      if (this._bureau.guid === guid) {
        return this._bureau;
      }
      const bureau_agg = this._bureauAgg.filter((agg: BureauAgg) => {
        return agg.bureau.guid == guid;
      });
      if (bureau_agg.length > 0) {
        this._bureau = bureau_agg[0].bureau;
        return this._bureau;
      }
      const url = `/bureau/by-guid/${guid}`;
      const resp = await AxiosClient.get(url);
      this._bureau = resp.data;
      return this._bureau;
    },
    async loadBureauBySlug(slug: string): Promise<Bureau> {
      if (this._bureau.slug === slug) {
        return this._bureau;
      }
      const bureau_agg = this._bureauAgg.filter((agg: BureauAgg) => {
        return agg.bureau.slug == slug;
      });
      if (bureau_agg.length > 0) {
        this._bureau = bureau_agg[0].bureau;
        return this._bureau;
      }
      const url = `/bureau/by-slug/${slug}`;
      const resp = await AxiosClient.get(url);
      this._bureau = resp.data;
      return this._bureau;
    },
    async loadBureauByClient(client: Client): Promise<Bureau> {
      const resp = await AxiosClient.get(`/bureau/by-client/${client.guid}`);
      this._bureau = resp.data;
      return this._bureau;
    },
    async loadBureaubyAlert(alert: Alert): Promise<Bureau> {
      const resp = await AxiosClient.get(`/bureau/by-alert/${alert.guid}`);
      this._bureau = resp.data;
      return this._bureau;
    },
    async focusBureau(bureau: Bureau): Promise<void> {
      this._bureau = bureau;
    },
    async loadAllBureaus(): Promise<BureauAgg[]> {
      if (this._bureauAgg.length > 0) {
        return this._bureauAgg;
      }
      const resp = await AxiosClient.get('/bureau/all');
      const localAgg: BureauAgg[] = []
      for (let idx = 0; idx < resp.data.length; idx++) {
        let agg = resp.data[idx];
        localAgg.push({
          bureau: agg.bureau,
          cohort_count: agg.cohort_count,
          client_count: agg.client_count,
          alert_count: agg.alert_count,
          open_count: agg.open_count,
          ack_count: agg.ack_count,
          sensorCount: 1,
          detectorCount: agg.detector_count,
        })
      }
      this._bureauAgg = localAgg;
      return this._bureauAgg;
    },
    async loadBureauFromRouter(): Promise<Bureau|null> {
      if (router.currentRoute.value.name == 'Bureau Executive Summary') {
        let slug = router.currentRoute.value.params.bureauSlug as string;
        this._loadedBureau = await this.loadBureauBySlug(slug);
      } else if (router.currentRoute.value.path.startsWith("/configure")) {
        let bureau_id = router.currentRoute.value.params.bureau_id as string;
        this._loadedBureau = await this.loadBureauByGuid(bureau_id);
      } else if (router.currentRoute.value.name == 'Alert Details') {
        const alertStore = useAlertStore();
        let alert_guid = router.currentRoute.value.params.guid as string;
        const alert = await alertStore.loadAlert(alert_guid);
        this._loadedBureau = await this.loadBureaubyAlert(alert);
      }
      return this._loadedBureau;
    }
  }
});