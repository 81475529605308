<template>
  <div class="integration-box">
    <div class="box">
      <div class="media">
        <div class="media-content">
          <img src="/public/google-cloud-logo.svg" alt="Google Cloud">
        </div>
      </div>
      <div class="content">
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.media-content {
  font-size: 20px;
  margin-top: 1px !important;
}
</style>