<script setup lang="ts">
import SiteNetworkDataDownloadSensorCard from '../base/SiteNetworkDataDownloadSensorCard.vue';

import { useSiteOnboardingModal } from '@/stores/siteOnboardingModal';

const siteOnboardingModal = useSiteOnboardingModal();
async function handleToggle() {
  await siteOnboardingModal.toggle({
    component: SiteNetworkDataDownloadSensorCard,
    routeName: 'onboarding-site-network-data-download-sensor-card',
    title: 'Personam.AI Sensor Agent',
  });
}
</script>
<template>
  <div class="integration-box" @click="handleToggle()">
    <div class="box" style="padding-top: .75rem; padding-bottom: 0;">
      <div class="media">
        <div class="media-left" style="width:50px; padding-top:.65rem;">
          <span style="font-size:40px; color: #83984B;" class="fa-solid fa-computer"></span>
        </div>
        <div class="media-content">
          Personam.AI<br/>
          Sensor Agent
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.media-content {
  font-size: 20px;
  padding-top: 2px;
  padding-left: 10px;
}
</style>