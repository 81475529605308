import { defineStore } from "pinia";
import type { Client } from "./client";
import { AxiosClient, response_to_message } from "./axios-client";
import type { ErrorMessage, Message} from "./message";

export type Bureau = {
  guid: string,
  name: string,
  slug: string,
};
export type EmailConfig = {
  configured: boolean,
}
export type SendEmailTest = {
  recipients: string[],
  subject: string,
  body: string,
}
export const useEmailStore = defineStore('email', {
  state: () => ({
    _config: {
      configured: false,
    } as EmailConfig,
  }),
  getters: {
    emailConfig: (state): EmailConfig => { return state._config; },
  },
  actions: {
    async sendTest(send_email_test: SendEmailTest, bureau: Bureau): Promise<Message|ErrorMessage> {
      const url = `/email/send-test/${bureau.guid}`;
      try {
        const resp = await AxiosClient.post(url, send_email_test);
        return response_to_message(resp);
      } catch (error: any) {
        const castedError: ErrorMessage = error;
        return castedError;
      }
    },
    async loadConfig(client: Client): Promise<EmailConfig> {
      return {
        configured: false,
      }
    }
  }
});