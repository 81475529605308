import { defineStore } from "pinia";
import { AxiosClient, type GeneralErrorMessage } from "./axios-client";
import { useConfigStore } from "./config";
import { useTenancyStore } from "./tenancy";
import { Message, MessageStatus, useMessageStore } from "./message";

export interface Detector {
  detector_id: string,
  detector_website: string,
  detector_token: string,
}
export const useAlertDetectorStore = defineStore("alertDetector", {
  state: () => ({
    detector: null as Detector | null,
  }),
  getters: {},
  actions: {
    async loadDetectorToken(): Promise<void> {
      const tenancyStore = useTenancyStore()
      const configStore = useConfigStore();
      const url = `${configStore.apiUrl}/adg/investigate/${tenancyStore.current_site?.guid}/token`;
      try {
        const response = await AxiosClient.get(url);
        if (response.status == 204) {
          console.error("Detector Token not configured for Operator");
          const message = new Message(MessageStatus.Danger, "Your user account has yet to be configured by Personam. Please contact Personam.ai support!");
          const messageStore = useMessageStore();
          messageStore.pushMessage(message);
        } else if (response.status == 200) {
          this.detector = response.data;
        } else {
          console.error("Unknown Error", response.status);
        }
      } catch (error: any) {
        const castedError: GeneralErrorMessage = error;
        this.detector = null
        console.error(castedError);
      }
    }
  }
});