<script setup lang="ts">
import SideBar from '@/components/tenant/SideBar.vue'
import { onMounted, ref } from 'vue'
import { useTenancyStore, type Customer } from '@/stores/tenancy'
import SMSView from './SMSView.vue'
import EmailView from './EmailView.vue'
const tenancyStore = useTenancyStore();
enum Notification {
  EMAIL = "email",
  SMS = "sms"
}
const current_notification = ref<Notification>(Notification.EMAIL);
onMounted(async () => {
  await tenancyStore.loadTenants()
});
</script>
<template>
  <main class="section columns">
    <div class="column is-one-quarter">
      <SideBar :tenants="tenancyStore.customers" />
    </div>
    <div class="column is-three-quarter">
      <div class="field has-addons">
        <p class="control">
          <button :class="[current_notification === Notification.EMAIL ? 'is-active button':'button']" @click="current_notification = Notification.EMAIL">
            <span class="icon is-small">
              <i class="fas fa-envelope"></i>
            </span>
            <span> Email </span>
          </button>
        </p>
        <p class="control">
          <button :class="[current_notification === Notification.SMS? 'is-active button':'button']" @click="current_notification = Notification.SMS">
            <span class="icon is-small">
              <i class="fas fa-phone"></i>
            </span>
            <span> Text Message </span>
          </button>
        </p>
      </div>
      <div v-if="current_notification === Notification.SMS ">
        <SMSView ></SMSView>
      </div>
      <div v-if="current_notification === Notification.EMAIL">
        <EmailView ></EmailView>
      </div>
    </div>
  </main>
</template>
<style scoped lang="sass">
.large-action-icon
  font-size: 32px
  line-height: 32px
.force-text-center
  text-align: center !important
.embold-link
  font-weight: 800
  font-size: 1.1em !important
</style>