<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useSiteOperatorStore, type Operator } from '@/stores/site-operator';

const siteOperatorStore = useSiteOperatorStore();
const { operators, currentOperator } = storeToRefs(siteOperatorStore);

async function selectOperator(operator: Operator): Promise<void> {
  siteOperatorStore.setCurrentOperator(operator);
}
async function addOperator(event: MouseEvent): Promise<void> {
  await siteOperatorStore.createOperator();
}
</script>
<template>
  <nav class="panel">
    <!-- <div class="panel-block">
      <p class="control has-icons-left">
        <input class="input" type="text" placeholder="Search">
        <span class="icon is-left">
          <i class="fas fa-search" aria-hidden="true"></i>
        </span>
      </p>
    </div> -->
    <a 
      class="panel-block"
      :class="{
        'is-active': currentOperator != null && currentOperator.guid === opt.guid
      }"
      v-for="opt in operators" @click="selectOperator(opt)">
      <span class="panel-icon" style="margin-right: 0.25em; font-size: 32px;">
        <i class="fas fa-user" aria-hidden="true"></i>
      </span>
      <span>
        {{ opt.name }} <br/> &lt;{{ opt.email }}&gt;
      </span>
    </a>
    <div class="panel-block">
      <button @click="addOperator($event)" class="button is-primary is-right">
        Add Operator
      </button>
    </div>
  </nav>
</template>