<script setup lang="ts">
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthenStore } from '@/stores/authen';
import { useProfileStore } from '@/stores/profile';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import router from '@/router';

const authenStore = useAuthenStore();
const { authenMessage } = storeToRefs(authenStore);
const profileStore = useProfileStore();
const { industries } = storeToRefs(profileStore);

const signupSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  industry: yup.string().required(),
  industryOther: yup.string().required().when('industry', {
    is: (industry: string) => industry === 'other',
    then: () => yup.string().required().min(3),
    otherwise: () => yup.string().notRequired(),
  }),
  password: yup.string().required().min(8),
  policy: yup.boolean().required().isTrue(),
});
async function handleSignUp(values: any): Promise<void> {
  const industry = values['industry'] === 'other' ? values['industryOther'] : values['industry'];
  await authenStore.signUp(values['name'], values['email'], values['password'], industry);
  if (authenMessage.value.hasError === false) {
    router.push({'name': 'site-onboarding-survey'});
    return;
  }
}
const showIndustryOther = ref(false);
async function handleIndustryChange(): Promise<void> {
  const industry = document.querySelector('select')?.value;
  if (industry === 'other') {
    showIndustryOther.value = true;
  } else {
    showIndustryOther.value = false;
  }
}
</script>
<template>
  <section class="is-full-width authen-background">
    <div class="columns">
      <div class="column is-half">
      </div>
      <div class="column is-half">
        <section class="authen-window">
          <h1 class="title"> Personam.ai </h1>
          <h2 class="subtitle"> Sign Up </h2>
          <h3 v-show="authenMessage.hasError === true" class="has-text-danger"> {{ authenMessage.message }} </h3>
          <Form @submit="handleSignUp" :validation-schema="signupSchema">
            <div class="field">
              <label class="label"> Name </label>
              <div class="control has-icons-left has-icons-right">
                <Field class="input" type="text" placeholder="Joseph Fansler" name="name" />
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
              <ErrorMessage name="name" >
                <p class="help is-danger"> Please input your name </p>
              </ErrorMessage>
            </div>
            <div class="field">
              <label class="label" for="industry"> Industry </label>
              <div class="control has-icons-left">
                <div class="select is-fullwidth">
                  <Field
                    name="industry"
                    as="select"
                    @update:model-value="handleIndustryChange()"
                    placeholder="Select Industry">
                    <option value=""> Select Industry </option>
                    <option v-for="industry in industries" :key="industry" :value="industry">
                      {{ industry }}
                    </option>
                    <option value="other"> Other </option>
                  </Field>
                </div>
                <div class="icon is-small is-left">
                  <i class="fas fa-industry"></i>
                </div>
              </div>
            </div>
            <div v-show="showIndustryOther === true" class="field">
              <label class="label" for="industryOther"> What industry is your business in? </label>
              <div class="control has-icons-left has-icons-right">
                <Field class="input" type="text" placeholder="Other Industry" name="industryOther" />
                <span class="icon is-small is-left">
                  <i class="fas fa-building"></i>
                </span>
              </div>
              <ErrorMessage name="industryOther" >
                <p class="help is-danger"> Please enter your business industry </p>
              </ErrorMessage>
            </div>
            <div class="field">
              <label class="label"> E-Mail </label>
              <div class="control has-icons-left has-icons-right">
                <Field class="input" type="email" placeholder="jfansler@personam.dev" name="email" />
                <span class="icon is-small is-left">
                  <i class="fas fa-envelope"></i>
                </span>
                <span class="icon is-small is-right">
                  <i class="fas fa-check"></i>
                </span>
              </div>
              <ErrorMessage name="email" >
                <p class="help is-danger"> Invalid E-Mail </p>
              </ErrorMessage>
            </div>
            <div class="field">
              <label class="label"> Password </label>
              <div class="control">
                <Field class="input" type="password" placeholder="password" name="password"/>
              </div>
              <ErrorMessage name="password">
                <p class="help is-danger"> Password must have at least 8 characters </p>
              </ErrorMessage>
            </div>
            <div class="field is-grouped">
              <div class="control">
                <button type="submit" class="button is-primary"> Sign Up </button>
              </div>
              <div class="control">
                <router-link :to="{'name': 'login'}" class="button is-secondary"> Return to Login </router-link>
              </div>
            </div>
            <div class="field">
              <Field v-slot="{ field }" name="policy" type="checkbox" :value="true">
                <label class="checkbox">
                  <input type="checkbox" v-bind="field" />
                  By checking this box, you agree to the Terms of Use and Privacy Policy.
                </label>
                <br/>
                <a target="_blank" href="https://personam.ai/terms-of-use/"> Terms of Service </a>
                &#38;
                <a target="_blank" href="https://personam.ai/privacy/"> Privacy Policy </a>
              </Field>
              <ErrorMessage name="policy">
                <p class="help is-danger"> Please agree to the Terms of Use and Privacy Policy </p>
              </ErrorMessage>
            </div>
          </Form>
        </section>
      </div>
    </div>
  </section>
</template>