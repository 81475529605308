<script setup lang="ts">
import { onMounted, ref, defineProps, type PropType } from "vue";
import { type Customer, type Site, State, useTenancyStore } from "@/stores/tenancy";
import router from '@/router'
const props = defineProps<{ site?: Site, index?: number }>();
const tenancyStore = useTenancyStore()
function tileClick (state: State) {
  const customerIndex = tenancyStore.customers.findIndex((val: Customer) => {
    return val.guid === tenancyStore.current_customer?.guid
  })
  if (props.index !== undefined) {
    tenancyStore.setCurrentSite(customerIndex, props.index)
  }
  tenancyStore.setCurrentState(state)
  router.push({ path: `/site/${tenancyStore.current_customer?.slug}/${tenancyStore.current_site?.slug}/${state}` })
}
</script>
<template>
  <div class="mb-6 column is-3">
    <div class="is-flex mb-3">
      <img class="is-50x50 mr-2" src="/public/personam-alert.svg" />
      <div
        class="is-flex is-flex-direction-column is-justify-content-space-between"
        style="height: 50px"
      >
        <p class="is-size-5">{{ props.site?.name }}</p>
        <p>{{ props.site?.name_abbreviation }}</p>
      </div>
    </div>
    <div class="">
      <div class="columns is-variable is-3">
        <a class="column is-12" href="#">
          <div @click="tileClick(State.ALERT)" class="box" style="outline: 1px solid;">
            <div class="p-3 alert-box " v-if="props.site?.open_alert_count != 0"> 
              <p class="has-text-centered is-size-5">Alerts</p>
              <p class="has-text-centered is-size-5 has-text-danger">{{ props.site?.open_alert_count }}</p>
            </div>
            <div class="p-3 alert-box " v-if="props.site?.open_alert_count == 0">
              <p class="has-text-centered is-size-5">No Alerts</p>
            </div>
          </div>
        </a> 
      </div>
    </div>
  </div>
</template>
<style scoped lang="sass">
.is-50x50
  width: 50px
  height: 50px
.alert-box 
    display: flex
    height: 84px
    flex-direction: column
    justify-content: center
    align-items: center
</style>
