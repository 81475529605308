<script setup lang="ts">
import OperatorPhoneNumber from '@/components/notification/OperatorPhoneNumber.vue';
import { computed, onMounted, ref } from 'vue';
import { useTenancyStore, type Customer } from '@/stores/tenancy'
const tenancyStore = useTenancyStore();
import {useSiteOperatorStore, type Operator} from '@/stores/site-operator';
const siteOperatorStore = useSiteOperatorStore();

enum ViewState {
  Draft,
  History
};
const currentViewState = ref<ViewState>(ViewState.Draft);
const isResponed = ref<boolean>(false);
onMounted(async () => {
  await siteOperatorStore.loadOperators(tenancyStore.current_customer!)

  if (siteOperatorStore.operators.length > 0) {
    isResponed.value = true;
  }
})

// Preview
const noteUrl = computed(() => {
  return window.location.href;
});
const smsBodyContentPreview = computed(() => {
  return `Threat Detected by Personam. Please review:
${noteUrl.value}`;
});
</script>
<template>
    <section class="columns">
      <div class="column is-three-quarters">
        <div v-if="currentViewState === ViewState.Draft" class="columns">
          <div class="column is-two-thirds">
            <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th> Operator </th>
                  <th> Receive SMS Notifications </th>
                </tr>
              </thead>
              <tbody v-if="isResponed">
                <tr v-for="oa in siteOperatorStore.operators" :key="oa.guid">
                  <td>
                    <span class="fa fa-user"></span>
                    {{ oa.name }}
                  </td>
                  <OperatorPhoneNumber :oa="oa" :smsNote="smsBodyContentPreview" />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
</template>